/* text alignment */
.align-left
{
	text-align: left;
}
.align-center
{
	text-align: center;
}
.align-right
{
	text-align: right;
}
.align-justify
{
	text-align: justify;
}

/* float alignments */
.float-left
{
	float: left;
}
.float-right
{
	float: right;
}
.float-none
{
	float: none;
}
.clear
{
	clear: both;
}
.clear-left
{
	clear: left;
}
.clear-right
{
	clear: right;
}
.clear-none
{
	clear: none;
}

.visible,
.display-block
{
	display: block;
}
.visible-inline,
.display-inline
{
	display: inline;
}
.visible-inline-block,
.display-inline-block
{
	display: inline-block;
}
.hidden,
.none
{
	display: none;
}

@media print
{
	.print-visible
	{
		display: block;
	}
	.print-visible-inline
	{
		display: inline;
	}
	.print-visible-inline-block
	{
		display: inline-block;
	}
	.print-hidden
	{
		display: none;
	}
}

.bold,
.strong
{
	font-weight: bold;
}
.italic
{
	font-style: italic;
}
.underline
{
	text-decoration: underline;
}
.overline
{
	text-decoration: overline;
}
.uppercase
{
	text-transform: uppercase;
}
.lowercase
{
	text-transform: lowercase;
}
.capitalize
{
	text-transform: capitalize;
}

.fluid-image
{
	max-width: 100%;
	height: auto;
}
.fluid-image-container img
{
	max-width: 100%;
	height: auto;
}
.fluid-image-upscale
{
	width: 100%;
	height: auto;
}
.fluid-image-upscale-container img
{
	width: 100%;
	height: auto;
}

/**
 * xxl 		>= 1281px
 * xxl-down <= 1281px
 */
@media only screen and (max-width: 1281px)
{
	.xxl-down-visible
	{
		display: block;
	}
	.xxl-down-visible-inline
	{
		display: inline;
	}
	.xxl-down-visible-inline-block
	{
		display: inline-block;
	}
	.xxl-down-hidden
	{
		display: none;
	}
	
	.xxl-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xxl-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xxl-down-align-left
	{
		text-align: left;
	}
	.xxl-down-align-center
	{
		text-align: center;
	}
	.xxl-down-align-right
	{
		text-align: right;
	}
	.xxl-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 1281px)
{
	.xxl-visible
	{
		display: block;
	}
	.xxl-visible-inline
	{
		display: inline;
	}
	.xxl-visible-inline-block
	{
		display: inline-block;
	}
	.xxl-hidden
	{
		display: none;
	}
	
	.xxl-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xxl-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xxl-align-left
	{
		text-align: left;
	}
	.xxl-align-center
	{
		text-align: center;
	}
	.xxl-align-right
	{
		text-align: right;
	}
	.xxl-align-justify
	{
		text-align: justify;
	}
}

/**
 * xl- 		1025px ~ 1281px
 * xl-up 	>= 1025px
 * xl-down 	<= 1281px
 */
@media only screen and (min-width: 1025px)
{
	.xl-up-visible
	{
		display: block;
	}
	.xl-up-visible-inline
	{
		display: inline;
	}
	.xl-up-visible-inline-block
	{
		display: inline-block;
	}
	.xl-up-hidden
	{
		display: none;
	}
	
	.xl-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-up-align-left
	{
		text-align: left;
	}
	.xl-up-align-center
	{
		text-align: center;
	}
	.xl-up-align-right
	{
		text-align: right;
	}
	.xl-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 1281px)
{
	.xl-down-visible
	{
		display: block;
	}
	.xl-down-visible-inline
	{
		display: inline;
	}
	.xl-down-visible-inline-block
	{
		display: inline-block;
	}
	.xl-down-hidden
	{
		display: none;
	}
	
	.xl-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-down-align-left
	{
		text-align: left;
	}
	.xl-down-align-center
	{
		text-align: center;
	}
	.xl-down-align-right
	{
		text-align: right;
	}
	.xl-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1281px)
{
	.xl-visible
	{
		display: block;
	}
	.xl-visible-inline
	{
		display: inline;
	}
	.xl-visible-inline-block
	{
		display: inline-block;
	}
	.xl-hidden
	{
		display: none;
	}
	
	.xl-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-align-left
	{
		text-align: left;
	}
	.xl-align-center
	{
		text-align: center;
	}
	.xl-align-right
	{
		text-align: right;
	}
	.xl-align-justify
	{
		text-align: justify;
	}
}

/**
 * l- 		801px ~ 1025px
 * l-up 	>= 801px
 * l-down 	<= 1025px
 */
@media only screen and (min-width: 801px)
{
	.l-up-visible
	{
		display: block;
	}
	.l-up-visible-inline
	{
		display: inline;
	}
	.l-up-visible-inline-block
	{
		display: inline-block;
	}
	.l-up-hidden
	{
		display: none;
	}
	
	.l-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-up-align-left
	{
		text-align: left;
	}
	.l-up-align-center
	{
		text-align: center;
	}
	.l-up-align-right
	{
		text-align: right;
	}
	.l-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 1025px)
{
	.l-down-visible
	{
		display: block;
	}
	.l-down-visible-inline
	{
		display: inline;
	}
	.l-down-visible-inline-block
	{
		display: inline-block;
	}
	.l-down-hidden
	{
		display: none;
	}
	
	.l-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-down-align-left
	{
		text-align: left;
	}
	.l-down-align-center
	{
		text-align: center;
	}
	.l-down-align-right
	{
		text-align: right;
	}
	.l-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 801px) and (max-width: 1025px)
{
	.l-visible
	{
		display: block;
	}
	.l-visible-inline
	{
		display: inline;
	}
	.l-visible-inline-block
	{
		display: inline-block;
	}
	.l-hidden
	{
		display: none;
	}
	
	.l-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-align-left
	{
		text-align: left;
	}
	.l-align-center
	{
		text-align: center;
	}
	.l-align-right
	{
		text-align: right;
	}
	.l-align-justify
	{
		text-align: justify;
	}
}

/**
 * m- 		600px ~ 801px
 * m-up 	>= 600px
 * m-down 	<= 801px
 */
@media only screen and (min-width: 600px)
{
	.m-up-visible
	{
		display: block;
	}
	.m-up-visible-inline
	{
		display: inline;
	}
	.m-up-visible-inline-block
	{
		display: inline-block;
	}
	.m-up-hidden
	{
		display: none;
	}
	
	.m-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-up-align-left
	{
		text-align: left;
	}
	.m-up-align-center
	{
		text-align: center;
	}
	.m-up-align-right
	{
		text-align: right;
	}
	.m-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 801px)
{
	.m-down-visible
	{
		display: block;
	}
	.m-down-visible-inline
	{
		display: inline;
	}
	.m-down-visible-inline-block
	{
		display: inline-block;
	}
	.m-down-hidden
	{
		display: none;
	}
	
	.m-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-down-align-left
	{
		text-align: left;
	}
	.m-down-align-center
	{
		text-align: center;
	}
	.m-down-align-right
	{
		text-align: right;
	}
	.m-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 600px) and (max-width: 801px)
{
	.m-visible
	{
		display: block;
	}
	.m-visible-inline
	{
		display: inline;
	}
	.m-visible-inline-block
	{
		display: inline-block;
	}
	.m-hidden
	{
		display: none;
	}
	
	.m-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-align-left
	{
		text-align: left;
	}
	.m-align-center
	{
		text-align: center;
	}
	.m-align-right
	{
		text-align: right;
	}
	.m-align-justify
	{
		text-align: justify;
	}
}

/**
 * s- 		480px ~ 600px
 * s-up 	>= 480px
 * s-down 	<= 600px
 */
@media only screen and (min-width: 480px)
{
	.s-up-visible
	{
		display: block;
	}
	.s-up-visible-inline
	{
		display: inline;
	}
	.s-up-visible-inline-block
	{
		display: inline-block;
	}
	.s-up-hidden
	{
		display: none;
	}
	
	.s-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-up-align-left
	{
		text-align: left;
	}
	.s-up-align-center
	{
		text-align: center;
	}
	.s-up-align-right
	{
		text-align: right;
	}
	.s-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 600px)
{
	.s-down-visible
	{
		display: block;
	}
	.s-down-visible-inline
	{
		display: inline;
	}
	.s-down-visible-inline-block
	{
		display: inline-block;
	}
	.s-down-hidden
	{
		display: none;
	}
	
	.s-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-down-align-left
	{
		text-align: left;
	}
	.s-down-align-center
	{
		text-align: center;
	}
	.s-down-align-right
	{
		text-align: right;
	}
	.s-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 480px) and (max-width: 600px)
{
	.s-visible
	{
		display: block;
	}
	.s-visible-inline
	{
		display: inline;
	}
	.s-visible-inline-block
	{
		display: inline-block;
	}
	.s-hidden
	{
		display: none;
	}
	
	.s-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-align-left
	{
		text-align: left;
	}
	.s-align-center
	{
		text-align: center;
	}
	.s-align-right
	{
		text-align: right;
	}
	.s-align-justify
	{
		text-align: justify;
	}
}

/**
 * xs- 		320px ~ 480px
 * xs-up 	>= 320px
 * xs-down 	<= 480px
 */
@media only screen and (min-width: 320px)
{
	.xs-up-visible
	{
		display: block;
	}
	.xs-up-visible-inline
	{
		display: inline;
	}
	.xs-up-visible-inline-block
	{
		display: inline-block;
	}
	.xs-up-hidden
	{
		display: none;
	}
	
	.xs-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-up-align-left
	{
		text-align: left;
	}
	.xs-up-align-center
	{
		text-align: center;
	}
	.xs-up-align-right
	{
		text-align: right;
	}
	.xs-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 480px)
{
	.xs-down-visible
	{
		display: block;
	}
	.xs-down-visible-inline
	{
		display: inline;
	}
	.xs-down-visible-inline-block
	{
		display: inline-block;
	}
	.xs-down-hidden
	{
		display: none;
	}
	
	.xs-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-down-align-left
	{
		text-align: left;
	}
	.xs-down-align-center
	{
		text-align: center;
	}
	.xs-down-align-right
	{
		text-align: right;
	}
	.xs-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px)
{
	.xs-visible
	{
		display: block;
	}
	.xs-visible-inline
	{
		display: inline;
	}
	.xs-visible-inline-block
	{
		display: inline-block;
	}
	.xs-hidden
	{
		display: none;
	}
	
	.xs-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-align-left
	{
		text-align: left;
	}
	.xs-align-center
	{
		text-align: center;
	}
	.xs-align-right
	{
		text-align: right;
	}
	.xs-align-justify
	{
		text-align: justify;
	}
}