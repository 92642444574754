/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video
{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, main, menu, nav, section, summary
{
	display: block;
}
body
{
	line-height: 1;
}
ol, ul
{
	list-style: none;
}
blockquote, q
{
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after
{
	content: '';
	content: none;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
}

/* extended by dispendium (only for html5) */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video
{
	box-sizing: border-box;
}

b, strong
{
	font-weight: bold;
}
i, em
{
	font-style: italic;
}

del
{
	text-decoration: line-through;
}
ins
{
	text-decoration: underline;
}

sub,
sup
{
	font-size: .83em;
}
sub
{
	vertical-align: sub;
}
sup
{
	vertical-align: super;
}

ul
{
	list-style-type: disc;
	list-style-position: inside;
	margin-left: 30px;
}
ol
{
	list-style-type: decimal;
	margin-left: 30px;
}
ul ul, ol ul
{
	list-style-type: circle;
	margin-left: 30px;
}
ol ol, ul ol
{
	list-style-type: lower-latin;
	margin-left: 30px;
}