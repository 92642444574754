html,
body
{
	background: #fff url('../image/background.jpg') no-repeat;
	background-size: cover;
	background-attachment: fixed;
	font-family: Scada, sans-serif;
	font-size: 16px;
	line-height: 1.618;
	color: #3c3c3c;
}

h1
{
	color: #c5bf33;
	font-size: 3rem;
	margin-bottom: 20px;
	font-weight: bold;
}
h1#home
{
	margin-bottom: 0;
	line-height: 1;
}
@media only screen and (max-width: 600px)
{
	h1
	{
		font-size: 2rem;
	}
}
h2
{
	font-size: 1.125rem;
	font-weight: bold;
	margin: 35px 0 10px 0;
}
h3
{
	font-weight: bold;
}

p
{
	margin-bottom: 15px;
}
p.condensed
{
	margin-bottom: 0;
}

a
{
	color: #c5bf33;
	text-decoration: underline;
}

	#page-container
	{
		max-width: 1200px;
		margin: 0 auto;
		background: #fff;
	}
		#header-container #navigation-container ul
		{
			list-style: none;
			float: right;
			padding: 10px 25px 25px 25px;
		}
		#header-container #navigation-container:after
		{
			content: '';
			display: block;
			clear: both;
		}
			#header-container #navigation-container ul li
			{
				display: inline-block;
				margin-left: 20px;
			}
			#header-container #navigation-container ul li:first-child
			{
				margin-left: 0;
			}
				#header-container #navigation-container ul li a
				{
					text-decoration: none;
					color: #3c3c3c;
					text-transform: uppercase;
				}
		#header-container #flex-container
		{
			display: flex;
			max-height: 330px;
		}
		@media only screen and (max-width: 1025px)
		{
			#header-container #flex-container
			{
				max-height: inherit;
				flex-direction: column;
			}
		}
			#header-container #logo-container
			{
				background: #c5bf33;
				border-right: 10px solid #fff;
				padding: 60px 120px;
			}
			@media only screen and (max-width: 1025px)
			{
				#header-container #logo-container
				{
					border: 0;
					border-bottom: 10px solid #fff;
					padding: 60px 20px;
				}
			}
				#header-container #logo-container a
				{
					display: block;
					margin: 0 auto;
				}
					#header-container #logo-container a img
					{
						display: block;
					}
					@media only screen and (max-width: 1025px)
					{
						#header-container #logo-container a img
						{
							margin: 0 auto;
						}
					}
			#header-container #slider-container
			{
				width: 100%;
				overflow: hidden;
				position: relative;
			}
			#header-container #slider-container .owl-item
			{
				-webkit-backface-visibility: visible;
			}
				#header-container #slider-container .owl-controls .owl-prev,
				#header-container #slider-container .owl-controls .owl-next
				{
					color: #fff;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: 5rem;
					text-shadow: 0 0 5px rgba(0,0,0,.5);
				}
				#header-container #slider-container .owl-controls .owl-prev
				{
					left: 10px;
				}
				#header-container #slider-container .owl-controls .owl-next
				{
					right: 10px;
				}
				#header-container #slider-container .owl-controls .owl-prev.disabled,
				#header-container #slider-container .owl-controls .owl-next.disabled
				{
					display: none !important;
				}
				
		#content-container
		{
			padding: 30px;
		}
				
		ul.inline-listing
		{
			text-align: center;
			list-style: none;
			margin-bottom: 20px;
		}
			ul.inline-listing li
			{
				display: inline;
			}
			ul.inline-listing li:after
			{
				content: '\2022';
				color: #c5bf33;
				margin: 0 5px;
			}
			ul.inline-listing li:last-child:after
			{
				content: '';
			}
				
		#contact-container
		{
			text-align: center;
		}
			#contact-container .contact
			{
				width: 45%;
				display: inline-block;
				text-align: left;
				margin: 10px 5px;
				border: 2px solid #eee;
				padding: 15px;
				border-radius: 3px;
			}
			@media only screen and (max-width: 1025px)
			{
				#contact-container .contact
				{
					display: block;
					width: 100%;
				}
				#contact-container .contact:last-child
				{
					display: block;
					margin-bottom: 0;
				}
			}
			#contact-container .contact:after
			{
				content: '';
				display: block;
				clear: both;
			}
				#contact-container .contact img
				{
					float: left;
					margin-right: 20px;
				}
				@media only screen and (max-width: 600px)
				{
					#contact-container .contact img
					{
						width: 100%;
						float: none;
						margin: 0 0 20px 0;
					}
				}
				#contact-container .contact .name
				{
					font-size: 1.25rem;
					color: #c5bf33;
					font-weight: bold;
					text-transform: uppercase;
				}
				#contact-container .contact .title
				{
					margin-bottom: 20px;
				}
				
	#footer-outer-container
	{
		background: #c5bf33;
		color: #fff;
		padding: 0 20px;
	}
		#footer-outer-container #footer-inner-container
		{
			max-width: 1200px;
			margin: 0 auto;
			padding: 15px 0;
			text-align: center;
		}
		
.cc_container
{
	border-radius: 0 !important;
	padding-bottom: 67px !important;
	border: none !important;
}
@media only screen and (max-width: 768px)
{
	.cc_container
	{
		padding-bottom: 57px !important;
	}
}
	.cc_container .cc_btn
	{
		border-radius: 0 !important;
		background: #c5bf33 !important;
		color: #fff !important;
		bottom: 17px !important; 
	}
	.cc_container .cc_logo
	{
		display: none !important;
	}