/*! dispendium-framework - v0.9.0 - 2016-01-14 09:39:50 *//* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video
{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, main, menu, nav, section, summary
{
	display: block;
}
body
{
	line-height: 1;
}
ol, ul
{
	list-style: none;
}
blockquote, q
{
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after
{
	content: '';
	content: none;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
}

/* extended by dispendium (only for html5) */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video
{
	box-sizing: border-box;
}

b, strong
{
	font-weight: bold;
}
i, em
{
	font-style: italic;
}

del
{
	text-decoration: line-through;
}
ins
{
	text-decoration: underline;
}

sub,
sup
{
	font-size: .83em;
}
sub
{
	vertical-align: sub;
}
sup
{
	vertical-align: super;
}

ul
{
	list-style-type: disc;
	list-style-position: inside;
	margin-left: 30px;
}
ol
{
	list-style-type: decimal;
	margin-left: 30px;
}
ul ul, ol ul
{
	list-style-type: circle;
	margin-left: 30px;
}
ol ol, ul ol
{
	list-style-type: lower-latin;
	margin-left: 30px;
}
.grid-row
{
	margin-left: -45px;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
}
.grid-row.blocked
{
	display: block;
}
.grid-row.blocked:after
{
	clear: both;
	content: '';
	display: block;
}

[class*="col-"]
{
	position: relative;
	padding-bottom: 45px;
	padding-left: 45px;
	box-sizing: border-box;
}
.grid-row.blocked [class*="col-"]
{
	float: left;
}
.grid-row.condensed [class*="col-"]
{
	padding-bottom: 0;
}

.col-flush
{
	padding-bottom: 0;
}

.col-1
{
	width: 8.333333333%;
}
.col-2
{
	width: 16.6666666666%;
}
.col-3
{
	width: 25%;
}
.col-4
{
	width: 33.3333333333%;
}
.col-5
{
	width: 41.6666666666%;
}
.col-6
{
	width: 50%;
}
.col-7
{
	width: 58.3333333333%;
}
.col-8
{
	width: 66.6666666666%;
}
.col-9
{
	width: 75%;
}
.col-10
{
	width: 83.3333333333%;
}
.col-11
{
	width: 91.6666666666%;
}
.col-12
{
	width: 100%;
}

.col-offset-0
{
	margin-left: 0;
}
.col-offset-1
{
	margin-left: 8.333333333%;
}
.col-offset-2
{
	margin-left: 16.6666666666%;
}
.col-offset-3
{
	margin-left: 25%;
}
.col-offset-4
{
	margin-left: 33.3333333333%;
}
.col-offset-5
{
	margin-left: 41.6666666666%;
}
.col-offset-6
{
	margin-left: 50%;
}
.col-offset-7
{
	margin-left: 58.3333333333%;
}
.col-offset-8
{
	margin-left: 66.6666666666%;
}
.col-offset-9
{
	margin-left: 75%;
}
.col-offset-10
{
	margin-left: 83.3333333333%;
}
.col-offset-11
{
	margin-left: 91.6666666666%;
}

.col-push-0
{
	left: 0;
}
.col-push-1
{
	left: 8.333333333%;
}
.col-push-2
{
	left: 16.6666666666%;
}
.col-push-3
{
	left: 25%;
}
.col-push-4
{
	left: 33.3333333333%;
}
.col-push-5
{
	left: 41.6666666666%;
}
.col-push-6
{
	left: 50%;
}
.col-push-7
{
	left: 58.3333333333%;
}
.col-push-8
{
	left: 66.6666666666%;
}
.col-push-9
{
	left: 75%;
}
.col-push-10
{
	left: 83.3333333333%;
}
.col-push-11
{
	left: 91.6666666666%;
}

.col-pull-0
{
	right: 0;
}
.col-pull-1
{
	right: 8.333333333%;
}
.col-pull-2
{
	right: 16.6666666666%;
}
.col-pull-3
{
	right: 25%;
}
.col-pull-4
{
	right: 33.3333333333%;
}
.col-pull-5
{
	right: 41.6666666666%;
}
.col-pull-6
{
	right: 50%;
}
.col-pull-7
{
	right: 58.3333333333%;
}
.col-pull-8
{
	right: 66.6666666666%;
}
.col-pull-9
{
	right: 75%;
}
.col-pull-10
{
	right: 83.3333333333%;
}
.col-pull-11
{
	right: 91.6666666666%;
}

.grid-row:last-child > [class*="col-"]:last-child
{
	padding-bottom: 0;
}

/**
 * xxl 		>= 1281px
 * xxl-down <= 1281px
 */
@media only screen and (max-width: 1281px)
{
	.grid-row.xxl-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}

	.xxl-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xxl-down-col-1
	{
		width: 8.333333333%;
	}
	.xxl-down-col-2
	{
		width: 16.6666666666%;
	}
	.xxl-down-col-3
	{
		width: 25%;
	}
	.xxl-down-col-4
	{
		width: 33.3333333333%;
	}
	.xxl-down-col-5
	{
		width: 41.6666666666%;
	}
	.xxl-down-col-6
	{
		width: 50%;
	}
	.xxl-down-col-7
	{
		width: 58.3333333333%;
	}
	.xxl-down-col-8
	{
		width: 66.6666666666%;
	}
	.xxl-down-col-9
	{
		width: 75%;
	}
	.xxl-down-col-10
	{
		width: 83.3333333333%;
	}
	.xxl-down-col-11
	{
		width: 91.6666666666%;
	}
	.xxl-down-col-12
	{
		width: 100%;
	}
	
	.xxl-down-col-offset-0
	{
		margin-left: 0;
	}
	.xxl-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xxl-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xxl-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xxl-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xxl-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xxl-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xxl-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xxl-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xxl-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xxl-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xxl-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xxl-down-col-push-0
	{
		left: 0;
	}
	.xxl-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xxl-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xxl-down-col-push-3
	{
		left: 25%;
	}
	.xxl-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xxl-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xxl-down-col-push-6
	{
		left: 50%;
	}
	.xxl-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xxl-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xxl-down-col-push-9
	{
		left: 75%;
	}
	.xxl-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xxl-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xxl-down-col-pull-0
	{
		right: 0;
	}
	.xxl-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xxl-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xxl-down-col-pull-3
	{
		right: 25%;
	}
	.xxl-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xxl-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xxl-down-col-pull-6
	{
		right: 50%;
	}
	.xxl-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xxl-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xxl-down-col-pull-9
	{
		right: 75%;
	}
	.xxl-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xxl-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xxl-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 1281px)
{
	.grid-row.xxl-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xxl-col-flush
	{
		padding-bottom: 0;
	}
	
	.xxl-col-1
	{
		width: 8.333333333%;
	}
	.xxl-col-2
	{
		width: 16.6666666666%;
	}
	.xxl-col-3
	{
		width: 25%;
	}
	.xxl-col-4
	{
		width: 33.3333333333%;
	}
	.xxl-col-5
	{
		width: 41.6666666666%;
	}
	.xxl-col-6
	{
		width: 50%;
	}
	.xxl-col-7
	{
		width: 58.3333333333%;
	}
	.xxl-col-8
	{
		width: 66.6666666666%;
	}
	.xxl-col-9
	{
		width: 75%;
	}
	.xxl-col-10
	{
		width: 83.3333333333%;
	}
	.xxl-col-11
	{
		width: 91.6666666666%;
	}
	.xxl-col-12
	{
		width: 100%;
	}
	
	.xxl-col-offset-0
	{
		margin-left: 0;
	}
	.xxl-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xxl-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xxl-col-offset-3
	{
		margin-left: 25%;
	}
	.xxl-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xxl-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xxl-col-offset-6
	{
		margin-left: 50%;
	}
	.xxl-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xxl-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xxl-col-offset-9
	{
		margin-left: 75%;
	}
	.xxl-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xxl-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xxl-col-push-0
	{
		left: 0;
	}
	.xxl-col-push-1
	{
		left: 8.333333333%;
	}
	.xxl-col-push-2
	{
		left: 16.6666666666%;
	}
	.xxl-col-push-3
	{
		left: 25%;
	}
	.xxl-col-push-4
	{
		left: 33.3333333333%;
	}
	.xxl-col-push-5
	{
		left: 41.6666666666%;
	}
	.xxl-col-push-6
	{
		left: 50%;
	}
	.xxl-col-push-7
	{
		left: 58.3333333333%;
	}
	.xxl-col-push-8
	{
		left: 66.6666666666%;
	}
	.xxl-col-push-9
	{
		left: 75%;
	}
	.xxl-col-push-10
	{
		left: 83.3333333333%;
	}
	.xxl-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xxl-col-pull-0
	{
		right: 0;
	}
	.xxl-col-pull-1
	{
		right: 8.333333333%;
	}
	.xxl-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xxl-col-pull-3
	{
		right: 25%;
	}
	.xxl-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xxl-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xxl-col-pull-6
	{
		right: 50%;
	}
	.xxl-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xxl-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xxl-col-pull-9
	{
		right: 75%;
	}
	.xxl-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xxl-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xxl-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * xl- 		1025px ~ 1281px
 * xl-up 	>= 1025px
 * xl-down 	<= 1281px
 */
@media only screen and (min-width: 1025px)
{
	.grid-row.xl-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-up-col-1
	{
		width: 8.333333333%;
	}
	.xl-up-col-2
	{
		width: 16.6666666666%;
	}
	.xl-up-col-3
	{
		width: 25%;
	}
	.xl-up-col-4
	{
		width: 33.3333333333%;
	}
	.xl-up-col-5
	{
		width: 41.6666666666%;
	}
	.xl-up-col-6
	{
		width: 50%;
	}
	.xl-up-col-7
	{
		width: 58.3333333333%;
	}
	.xl-up-col-8
	{
		width: 66.6666666666%;
	}
	.xl-up-col-9
	{
		width: 75%;
	}
	.xl-up-col-10
	{
		width: 83.3333333333%;
	}
	.xl-up-col-11
	{
		width: 91.6666666666%;
	}
	.xl-up-col-12
	{
		width: 100%;
	}
	
	.xl-up-col-offset-0
	{
		margin-left: 0;
	}
	.xl-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-up-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-up-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-up-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-up-col-push-0
	{
		left: 0;
	}
	.xl-up-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-up-col-push-3
	{
		left: 25%;
	}
	.xl-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-up-col-push-6
	{
		left: 50%;
	}
	.xl-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-up-col-push-9
	{
		left: 75%;
	}
	.xl-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-up-col-pull-0
	{
		right: 0;
	}
	.xl-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-up-col-pull-3
	{
		right: 25%;
	}
	.xl-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-up-col-pull-6
	{
		right: 50%;
	}
	.xl-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-up-col-pull-9
	{
		right: 75%;
	}
	.xl-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 1281px)
{
	.grid-row.xl-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-down-col-1
	{
		width: 8.333333333%;
	}
	.xl-down-col-2
	{
		width: 16.6666666666%;
	}
	.xl-down-col-3
	{
		width: 25%;
	}
	.xl-down-col-4
	{
		width: 33.3333333333%;
	}
	.xl-down-col-5
	{
		width: 41.6666666666%;
	}
	.xl-down-col-6
	{
		width: 50%;
	}
	.xl-down-col-7
	{
		width: 58.3333333333%;
	}
	.xl-down-col-8
	{
		width: 66.6666666666%;
	}
	.xl-down-col-9
	{
		width: 75%;
	}
	.xl-down-col-10
	{
		width: 83.3333333333%;
	}
	.xl-down-col-11
	{
		width: 91.6666666666%;
	}
	.xl-down-col-12
	{
		width: 100%;
	}
	
	.xl-down-col-offset-0
	{
		margin-left: 0;
	}
	.xl-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-down-col-push-0
	{
		left: 0;
	}
	.xl-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-down-col-push-3
	{
		left: 25%;
	}
	.xl-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-down-col-push-6
	{
		left: 50%;
	}
	.xl-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-down-col-push-9
	{
		left: 75%;
	}
	.xl-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-down-col-pull-0
	{
		right: 0;
	}
	.xl-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-down-col-pull-3
	{
		right: 25%;
	}
	.xl-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-down-col-pull-6
	{
		right: 50%;
	}
	.xl-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-down-col-pull-9
	{
		right: 75%;
	}
	.xl-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1281px)
{
	.grid-row.xl-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-col-1
	{
		width: 8.333333333%;
	}
	.xl-col-2
	{
		width: 16.6666666666%;
	}
	.xl-col-3
	{
		width: 25%;
	}
	.xl-col-4
	{
		width: 33.3333333333%;
	}
	.xl-col-5
	{
		width: 41.6666666666%;
	}
	.xl-col-6
	{
		width: 50%;
	}
	.xl-col-7
	{
		width: 58.3333333333%;
	}
	.xl-col-8
	{
		width: 66.6666666666%;
	}
	.xl-col-9
	{
		width: 75%;
	}
	.xl-col-10
	{
		width: 83.3333333333%;
	}
	.xl-col-11
	{
		width: 91.6666666666%;
	}
	.xl-col-12
	{
		width: 100%;
	}
	
	.xl-col-offset-0
	{
		margin-left: 0;
	}
	.xl-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-col-push-0
	{
		left: 0;
	}
	.xl-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-col-push-3
	{
		left: 25%;
	}
	.xl-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-col-push-6
	{
		left: 50%;
	}
	.xl-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-col-push-9
	{
		left: 75%;
	}
	.xl-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-col-pull-0
	{
		right: 0;
	}
	.xl-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-col-pull-3
	{
		right: 25%;
	}
	.xl-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-col-pull-6
	{
		right: 50%;
	}
	.xl-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-col-pull-9
	{
		right: 75%;
	}
	.xl-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * l- 		801px ~ 1025px
 * l-up 	>= 801px
 * l-down 	<= 1025px
 */
@media only screen and (min-width: 801px)
{
	.grid-row.l-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-up-col-1
	{
		width: 8.333333333%;
	}
	.l-up-col-2
	{
		width: 16.6666666666%;
	}
	.l-up-col-3
	{
		width: 25%;
	}
	.l-up-col-4
	{
		width: 33.3333333333%;
	}
	.l-up-col-5
	{
		width: 41.6666666666%;
	}
	.l-up-col-6
	{
		width: 50%;
	}
	.l-up-col-7
	{
		width: 58.3333333333%;
	}
	.l-up-col-8
	{
		width: 66.6666666666%;
	}
	.l-up-col-9
	{
		width: 75%;
	}
	.l-up-col-10
	{
		width: 83.3333333333%;
	}
	.l-up-col-11
	{
		width: 91.6666666666%;
	}
	.l-up-col-12
	{
		width: 100%;
	}
	
	.l-up-col-offset-0
	{
		margin-left: 0;
	}
	.l-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-up-col-offset-3
	{
		margin-left: 25%;
	}
	.l-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-up-col-offset-6
	{
		margin-left: 50%;
	}
	.l-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-up-col-offset-9
	{
		margin-left: 75%;
	}
	.l-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-up-col-push-0
	{
		left: 0;
	}
	.l-up-col-push-1
	{
		left: 8.333333333%;
	}
	.l-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-up-col-push-3
	{
		left: 25%;
	}
	.l-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-up-col-push-6
	{
		left: 50%;
	}
	.l-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-up-col-push-9
	{
		left: 75%;
	}
	.l-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-up-col-pull-0
	{
		right: 0;
	}
	.l-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-up-col-pull-3
	{
		right: 25%;
	}
	.l-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-up-col-pull-6
	{
		right: 50%;
	}
	.l-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-up-col-pull-9
	{
		right: 75%;
	}
	.l-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 1025px)
{
	.grid-row.l-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-down-col-1
	{
		width: 8.333333333%;
	}
	.l-down-col-2
	{
		width: 16.6666666666%;
	}
	.l-down-col-3
	{
		width: 25%;
	}
	.l-down-col-4
	{
		width: 33.3333333333%;
	}
	.l-down-col-5
	{
		width: 41.6666666666%;
	}
	.l-down-col-6
	{
		width: 50%;
	}
	.l-down-col-7
	{
		width: 58.3333333333%;
	}
	.l-down-col-8
	{
		width: 66.6666666666%;
	}
	.l-down-col-9
	{
		width: 75%;
	}
	.l-down-col-10
	{
		width: 83.3333333333%;
	}
	.l-down-col-11
	{
		width: 91.6666666666%;
	}
	.l-down-col-12
	{
		width: 100%;
	}
	
	.l-down-col-offset-0
	{
		margin-left: 0;
	}
	.l-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-down-col-offset-3
	{
		margin-left: 25%;
	}
	.l-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-down-col-offset-6
	{
		margin-left: 50%;
	}
	.l-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-down-col-offset-9
	{
		margin-left: 75%;
	}
	.l-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-down-col-push-0
	{
		left: 0;
	}
	.l-down-col-push-1
	{
		left: 8.333333333%;
	}
	.l-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-down-col-push-3
	{
		left: 25%;
	}
	.l-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-down-col-push-6
	{
		left: 50%;
	}
	.l-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-down-col-push-9
	{
		left: 75%;
	}
	.l-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-down-col-pull-0
	{
		right: 0;
	}
	.l-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-down-col-pull-3
	{
		right: 25%;
	}
	.l-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-down-col-pull-6
	{
		right: 50%;
	}
	.l-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-down-col-pull-9
	{
		right: 75%;
	}
	.l-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 801px) and (max-width: 1025px)
{
	.grid-row.l-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-col-1
	{
		width: 8.333333333%;
	}
	.l-col-2
	{
		width: 16.6666666666%;
	}
	.l-col-3
	{
		width: 25%;
	}
	.l-col-4
	{
		width: 33.3333333333%;
	}
	.l-col-5
	{
		width: 41.6666666666%;
	}
	.l-col-6
	{
		width: 50%;
	}
	.l-col-7
	{
		width: 58.3333333333%;
	}
	.l-col-8
	{
		width: 66.6666666666%;
	}
	.l-col-9
	{
		width: 75%;
	}
	.l-col-10
	{
		width: 83.3333333333%;
	}
	.l-col-11
	{
		width: 91.6666666666%;
	}
	.l-col-12
	{
		width: 100%;
	}
	
	.l-col-offset-0
	{
		margin-left: 0;
	}
	.l-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-col-offset-3
	{
		margin-left: 25%;
	}
	.l-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-col-offset-6
	{
		margin-left: 50%;
	}
	.l-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-col-offset-9
	{
		margin-left: 75%;
	}
	.l-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-col-push-0
	{
		left: 0;
	}
	.l-col-push-1
	{
		left: 8.333333333%;
	}
	.l-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-col-push-3
	{
		left: 25%;
	}
	.l-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-col-push-6
	{
		left: 50%;
	}
	.l-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-col-push-9
	{
		left: 75%;
	}
	.l-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-col-pull-0
	{
		right: 0;
	}
	.l-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-col-pull-3
	{
		right: 25%;
	}
	.l-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-col-pull-6
	{
		right: 50%;
	}
	.l-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-col-pull-9
	{
		right: 75%;
	}
	.l-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * m- 		600px ~ 801px
 * m-up 	>= 600px
 * m-down 	<= 801px
 */
@media only screen and (min-width: 600px)
{
	.grid-row.m-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-up-col-1
	{
		width: 8.333333333%;
	}
	.m-up-col-2
	{
		width: 16.6666666666%;
	}
	.m-up-col-3
	{
		width: 25%;
	}
	.m-up-col-4
	{
		width: 33.3333333333%;
	}
	.m-up-col-5
	{
		width: 41.6666666666%;
	}
	.m-up-col-6
	{
		width: 50%;
	}
	.m-up-col-7
	{
		width: 58.3333333333%;
	}
	.m-up-col-8
	{
		width: 66.6666666666%;
	}
	.m-up-col-9
	{
		width: 75%;
	}
	.m-up-col-10
	{
		width: 83.3333333333%;
	}
	.m-up-col-11
	{
		width: 91.6666666666%;
	}
	.m-up-col-12
	{
		width: 100%;
	}
	
	.m-up-col-offset-0
	{
		margin-left: 0;
	}
	.m-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-up-col-offset-3
	{
		margin-left: 25%;
	}
	.m-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-up-col-offset-6
	{
		margin-left: 50%;
	}
	.m-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-up-col-offset-9
	{
		margin-left: 75%;
	}
	.m-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-up-col-push-0
	{
		left: 0;
	}
	.m-up-col-push-1
	{
		left: 8.333333333%;
	}
	.m-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-up-col-push-3
	{
		left: 25%;
	}
	.m-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-up-col-push-6
	{
		left: 50%;
	}
	.m-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-up-col-push-9
	{
		left: 75%;
	}
	.m-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-up-col-pull-0
	{
		right: 0;
	}
	.m-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-up-col-pull-3
	{
		right: 25%;
	}
	.m-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-up-col-pull-6
	{
		right: 50%;
	}
	.m-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-up-col-pull-9
	{
		right: 75%;
	}
	.m-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 801px)
{
	.grid-row.m-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-down-col-1
	{
		width: 8.333333333%;
	}
	.m-down-col-2
	{
		width: 16.6666666666%;
	}
	.m-down-col-3
	{
		width: 25%;
	}
	.m-down-col-4
	{
		width: 33.3333333333%;
	}
	.m-down-col-5
	{
		width: 41.6666666666%;
	}
	.m-down-col-6
	{
		width: 50%;
	}
	.m-down-col-7
	{
		width: 58.3333333333%;
	}
	.m-down-col-8
	{
		width: 66.6666666666%;
	}
	.m-down-col-9
	{
		width: 75%;
	}
	.m-down-col-10
	{
		width: 83.3333333333%;
	}
	.m-down-col-11
	{
		width: 91.6666666666%;
	}
	.m-down-col-12
	{
		width: 100%;
	}
	
	.m-down-col-offset-0
	{
		margin-left: 0;
	}
	.m-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-down-col-offset-3
	{
		margin-left: 25%;
	}
	.m-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-down-col-offset-6
	{
		margin-left: 50%;
	}
	.m-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-down-col-offset-9
	{
		margin-left: 75%;
	}
	.m-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-down-col-push-0
	{
		left: 0;
	}
	.m-down-col-push-1
	{
		left: 8.333333333%;
	}
	.m-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-down-col-push-3
	{
		left: 25%;
	}
	.m-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-down-col-push-6
	{
		left: 50%;
	}
	.m-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-down-col-push-9
	{
		left: 75%;
	}
	.m-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-down-col-pull-0
	{
		right: 0;
	}
	.m-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-down-col-pull-3
	{
		right: 25%;
	}
	.m-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-down-col-pull-6
	{
		right: 50%;
	}
	.m-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-down-col-pull-9
	{
		right: 75%;
	}
	.m-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 600px) and (max-width: 801px)
{
	.grid-row.m-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-col-1
	{
		width: 8.333333333%;
	}
	.m-col-2
	{
		width: 16.6666666666%;
	}
	.m-col-3
	{
		width: 25%;
	}
	.m-col-4
	{
		width: 33.3333333333%;
	}
	.m-col-5
	{
		width: 41.6666666666%;
	}
	.m-col-6
	{
		width: 50%;
	}
	.m-col-7
	{
		width: 58.3333333333%;
	}
	.m-col-8
	{
		width: 66.6666666666%;
	}
	.m-col-9
	{
		width: 75%;
	}
	.m-col-10
	{
		width: 83.3333333333%;
	}
	.m-col-11
	{
		width: 91.6666666666%;
	}
	.m-col-12
	{
		width: 100%;
	}
	
	.m-col-offset-0
	{
		margin-left: 0;
	}
	.m-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-col-offset-3
	{
		margin-left: 25%;
	}
	.m-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-col-offset-6
	{
		margin-left: 50%;
	}
	.m-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-col-offset-9
	{
		margin-left: 75%;
	}
	.m-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-col-push-0
	{
		left: 0;
	}
	.m-col-push-1
	{
		left: 8.333333333%;
	}
	.m-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-col-push-3
	{
		left: 25%;
	}
	.m-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-col-push-6
	{
		left: 50%;
	}
	.m-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-col-push-9
	{
		left: 75%;
	}
	.m-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-col-pull-0
	{
		right: 0;
	}
	.m-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-col-pull-3
	{
		right: 25%;
	}
	.m-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-col-pull-6
	{
		right: 50%;
	}
	.m-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-col-pull-9
	{
		right: 75%;
	}
	.m-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * s- 		480px ~ 600px
 * s-up 	>= 480px
 * s-down 	<= 600px
 */
@media only screen and (min-width: 480px)
{
	.grid-row.s-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-up-col-1
	{
		width: 8.333333333%;
	}
	.s-up-col-2
	{
		width: 16.6666666666%;
	}
	.s-up-col-3
	{
		width: 25%;
	}
	.s-up-col-4
	{
		width: 33.3333333333%;
	}
	.s-up-col-5
	{
		width: 41.6666666666%;
	}
	.s-up-col-6
	{
		width: 50%;
	}
	.s-up-col-7
	{
		width: 58.3333333333%;
	}
	.s-up-col-8
	{
		width: 66.6666666666%;
	}
	.s-up-col-9
	{
		width: 75%;
	}
	.s-up-col-10
	{
		width: 83.3333333333%;
	}
	.s-up-col-11
	{
		width: 91.6666666666%;
	}
	.s-up-col-12
	{
		width: 100%;
	}
	
	.s-up-col-offset-0
	{
		margin-left: 0;
	}
	.s-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-up-col-offset-3
	{
		margin-left: 25%;
	}
	.s-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-up-col-offset-6
	{
		margin-left: 50%;
	}
	.s-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-up-col-offset-9
	{
		margin-left: 75%;
	}
	.s-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-up-col-push-0
	{
		left: 0;
	}
	.s-up-col-push-1
	{
		left: 8.333333333%;
	}
	.s-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-up-col-push-3
	{
		left: 25%;
	}
	.s-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-up-col-push-6
	{
		left: 50%;
	}
	.s-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-up-col-push-9
	{
		left: 75%;
	}
	.s-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-up-col-pull-0
	{
		right: 0;
	}
	.s-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-up-col-pull-3
	{
		right: 25%;
	}
	.s-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-up-col-pull-6
	{
		right: 50%;
	}
	.s-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-up-col-pull-9
	{
		right: 75%;
	}
	.s-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 600px)
{
	.grid-row.s-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-down-col-1
	{
		width: 8.333333333%;
	}
	.s-down-col-2
	{
		width: 16.6666666666%;
	}
	.s-down-col-3
	{
		width: 25%;
	}
	.s-down-col-4
	{
		width: 33.3333333333%;
	}
	.s-down-col-5
	{
		width: 41.6666666666%;
	}
	.s-down-col-6
	{
		width: 50%;
	}
	.s-down-col-7
	{
		width: 58.3333333333%;
	}
	.s-down-col-8
	{
		width: 66.6666666666%;
	}
	.s-down-col-9
	{
		width: 75%;
	}
	.s-down-col-10
	{
		width: 83.3333333333%;
	}
	.s-down-col-11
	{
		width: 91.6666666666%;
	}
	.s-down-col-12
	{
		width: 100%;
	}
	
	.s-down-col-offset-0
	{
		margin-left: 0;
	}
	.s-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-down-col-offset-3
	{
		margin-left: 25%;
	}
	.s-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-down-col-offset-6
	{
		margin-left: 50%;
	}
	.s-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-down-col-offset-9
	{
		margin-left: 75%;
	}
	.s-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-down-col-push-0
	{
		left: 0;
	}
	.s-down-col-push-1
	{
		left: 8.333333333%;
	}
	.s-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-down-col-push-3
	{
		left: 25%;
	}
	.s-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-down-col-push-6
	{
		left: 50%;
	}
	.s-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-down-col-push-9
	{
		left: 75%;
	}
	.s-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-down-col-pull-0
	{
		right: 0;
	}
	.s-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-down-col-pull-3
	{
		right: 25%;
	}
	.s-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-down-col-pull-6
	{
		right: 50%;
	}
	.s-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-down-col-pull-9
	{
		right: 75%;
	}
	.s-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 480px) and (max-width: 600px)
{
	.grid-row.s-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-col-1
	{
		width: 8.333333333%;
	}
	.s-col-2
	{
		width: 16.6666666666%;
	}
	.s-col-3
	{
		width: 25%;
	}
	.s-col-4
	{
		width: 33.3333333333%;
	}
	.s-col-5
	{
		width: 41.6666666666%;
	}
	.s-col-6
	{
		width: 50%;
	}
	.s-col-7
	{
		width: 58.3333333333%;
	}
	.s-col-8
	{
		width: 66.6666666666%;
	}
	.s-col-9
	{
		width: 75%;
	}
	.s-col-10
	{
		width: 83.3333333333%;
	}
	.s-col-11
	{
		width: 91.6666666666%;
	}
	.s-col-12
	{
		width: 100%;
	}
	
	.s-col-offset-0
	{
		margin-left: 0;
	}
	.s-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-col-offset-3
	{
		margin-left: 25%;
	}
	.s-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-col-offset-6
	{
		margin-left: 50%;
	}
	.s-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-col-offset-9
	{
		margin-left: 75%;
	}
	.s-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-col-push-0
	{
		left: 0;
	}
	.s-col-push-1
	{
		left: 8.333333333%;
	}
	.s-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-col-push-3
	{
		left: 25%;
	}
	.s-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-col-push-6
	{
		left: 50%;
	}
	.s-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-col-push-9
	{
		left: 75%;
	}
	.s-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-col-pull-0
	{
		right: 0;
	}
	.s-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-col-pull-3
	{
		right: 25%;
	}
	.s-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-col-pull-6
	{
		right: 50%;
	}
	.s-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-col-pull-9
	{
		right: 75%;
	}
	.s-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * xs- 		320px ~ 480px
 * xs-up 	>= 320px
 * xs-down 	<= 480px
 */
@media only screen and (min-width: 320px)
{
	.grid-row.xs-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-up-col-1
	{
		width: 8.333333333%;
	}
	.xs-up-col-2
	{
		width: 16.6666666666%;
	}
	.xs-up-col-3
	{
		width: 25%;
	}
	.xs-up-col-4
	{
		width: 33.3333333333%;
	}
	.xs-up-col-5
	{
		width: 41.6666666666%;
	}
	.xs-up-col-6
	{
		width: 50%;
	}
	.xs-up-col-7
	{
		width: 58.3333333333%;
	}
	.xs-up-col-8
	{
		width: 66.6666666666%;
	}
	.xs-up-col-9
	{
		width: 75%;
	}
	.xs-up-col-10
	{
		width: 83.3333333333%;
	}
	.xs-up-col-11
	{
		width: 91.6666666666%;
	}
	.xs-up-col-12
	{
		width: 100%;
	}
	
	.xs-up-col-offset-0
	{
		margin-left: 0;
	}
	.xs-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-up-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-up-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-up-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-up-col-push-0
	{
		left: 0;
	}
	.xs-up-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-up-col-push-3
	{
		left: 25%;
	}
	.xs-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-up-col-push-6
	{
		left: 50%;
	}
	.xs-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-up-col-push-9
	{
		left: 75%;
	}
	.xs-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-up-col-pull-0
	{
		right: 0;
	}
	.xs-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-up-col-pull-3
	{
		right: 25%;
	}
	.xs-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-up-col-pull-6
	{
		right: 50%;
	}
	.xs-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-up-col-pull-9
	{
		right: 75%;
	}
	.xs-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 480px)
{
	.grid-row.xs-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-down-col-1
	{
		width: 8.333333333%;
	}
	.xs-down-col-2
	{
		width: 16.6666666666%;
	}
	.xs-down-col-3
	{
		width: 25%;
	}
	.xs-down-col-4
	{
		width: 33.3333333333%;
	}
	.xs-down-col-5
	{
		width: 41.6666666666%;
	}
	.xs-down-col-6
	{
		width: 50%;
	}
	.xs-down-col-7
	{
		width: 58.3333333333%;
	}
	.xs-down-col-8
	{
		width: 66.6666666666%;
	}
	.xs-down-col-9
	{
		width: 75%;
	}
	.xs-down-col-10
	{
		width: 83.3333333333%;
	}
	.xs-down-col-11
	{
		width: 91.6666666666%;
	}
	.xs-down-col-12
	{
		width: 100%;
	}
	
	.xs-down-col-offset-0
	{
		margin-left: 0;
	}
	.xs-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-down-col-push-0
	{
		left: 0;
	}
	.xs-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-down-col-push-3
	{
		left: 25%;
	}
	.xs-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-down-col-push-6
	{
		left: 50%;
	}
	.xs-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-down-col-push-9
	{
		left: 75%;
	}
	.xs-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-down-col-pull-0
	{
		right: 0;
	}
	.xs-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-down-col-pull-3
	{
		right: 25%;
	}
	.xs-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-down-col-pull-6
	{
		right: 50%;
	}
	.xs-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-down-col-pull-9
	{
		right: 75%;
	}
	.xs-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px)
{
	.grid-row.xs-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-col-1
	{
		width: 8.333333333%;
	}
	.xs-col-2
	{
		width: 16.6666666666%;
	}
	.xs-col-3
	{
		width: 25%;
	}
	.xs-col-4
	{
		width: 33.3333333333%;
	}
	.xs-col-5
	{
		width: 41.6666666666%;
	}
	.xs-col-6
	{
		width: 50%;
	}
	.xs-col-7
	{
		width: 58.3333333333%;
	}
	.xs-col-8
	{
		width: 66.6666666666%;
	}
	.xs-col-9
	{
		width: 75%;
	}
	.xs-col-10
	{
		width: 83.3333333333%;
	}
	.xs-col-11
	{
		width: 91.6666666666%;
	}
	.xs-col-12
	{
		width: 100%;
	}
	
	.xs-col-offset-0
	{
		margin-left: 0;
	}
	.xs-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-col-push-0
	{
		left: 0;
	}
	.xs-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-col-push-3
	{
		left: 25%;
	}
	.xs-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-col-push-6
	{
		left: 50%;
	}
	.xs-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-col-push-9
	{
		left: 75%;
	}
	.xs-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-col-pull-0
	{
		right: 0;
	}
	.xs-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-col-pull-3
	{
		right: 25%;
	}
	.xs-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-col-pull-6
	{
		right: 50%;
	}
	.xs-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-col-pull-9
	{
		right: 75%;
	}
	.xs-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

html,
body
{
	background: #fff url('../image/background.jpg') no-repeat;
	background-size: cover;
	background-attachment: fixed;
	font-family: Scada, sans-serif;
	font-size: 16px;
	line-height: 1.618;
	color: #3c3c3c;
}

h1
{
	color: #c5bf33;
	font-size: 3rem;
	margin-bottom: 20px;
	font-weight: bold;
}
h1#home
{
	margin-bottom: 0;
	line-height: 1;
}
@media only screen and (max-width: 600px)
{
	h1
	{
		font-size: 2rem;
	}
}
h2
{
	font-size: 1.125rem;
	font-weight: bold;
	margin: 35px 0 10px 0;
}
h3
{
	font-weight: bold;
}

p
{
	margin-bottom: 15px;
}
p.condensed
{
	margin-bottom: 0;
}

a
{
	color: #c5bf33;
	text-decoration: underline;
}

	#page-container
	{
		max-width: 1200px;
		margin: 0 auto;
		background: #fff;
	}
		#header-container #navigation-container ul
		{
			list-style: none;
			float: right;
			padding: 10px 25px 25px 25px;
		}
		#header-container #navigation-container:after
		{
			content: '';
			display: block;
			clear: both;
		}
			#header-container #navigation-container ul li
			{
				display: inline-block;
				margin-left: 20px;
			}
			#header-container #navigation-container ul li:first-child
			{
				margin-left: 0;
			}
				#header-container #navigation-container ul li a
				{
					text-decoration: none;
					color: #3c3c3c;
					text-transform: uppercase;
				}
		#header-container #flex-container
		{
			display: flex;
			max-height: 330px;
		}
		@media only screen and (max-width: 1025px)
		{
			#header-container #flex-container
			{
				max-height: inherit;
				flex-direction: column;
			}
		}
			#header-container #logo-container
			{
				background: #c5bf33;
				border-right: 10px solid #fff;
				padding: 60px 120px;
			}
			@media only screen and (max-width: 1025px)
			{
				#header-container #logo-container
				{
					border: 0;
					border-bottom: 10px solid #fff;
					padding: 60px 20px;
				}
			}
				#header-container #logo-container a
				{
					display: block;
					margin: 0 auto;
				}
					#header-container #logo-container a img
					{
						display: block;
					}
					@media only screen and (max-width: 1025px)
					{
						#header-container #logo-container a img
						{
							margin: 0 auto;
						}
					}
			#header-container #slider-container
			{
				width: 100%;
				overflow: hidden;
				position: relative;
			}
			#header-container #slider-container .owl-item
			{
				-webkit-backface-visibility: visible;
			}
				#header-container #slider-container .owl-controls .owl-prev,
				#header-container #slider-container .owl-controls .owl-next
				{
					color: #fff;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: 5rem;
					text-shadow: 0 0 5px rgba(0,0,0,.5);
				}
				#header-container #slider-container .owl-controls .owl-prev
				{
					left: 10px;
				}
				#header-container #slider-container .owl-controls .owl-next
				{
					right: 10px;
				}
				#header-container #slider-container .owl-controls .owl-prev.disabled,
				#header-container #slider-container .owl-controls .owl-next.disabled
				{
					display: none !important;
				}
				
		#content-container
		{
			padding: 30px;
		}
				
		ul.inline-listing
		{
			text-align: center;
			list-style: none;
			margin-bottom: 20px;
		}
			ul.inline-listing li
			{
				display: inline;
			}
			ul.inline-listing li:after
			{
				content: '\2022';
				color: #c5bf33;
				margin: 0 5px;
			}
			ul.inline-listing li:last-child:after
			{
				content: '';
			}
				
		#contact-container
		{
			text-align: center;
		}
			#contact-container .contact
			{
				width: 45%;
				display: inline-block;
				text-align: left;
				margin: 10px 5px;
				border: 2px solid #eee;
				padding: 15px;
				border-radius: 3px;
			}
			@media only screen and (max-width: 1025px)
			{
				#contact-container .contact
				{
					display: block;
					width: 100%;
				}
				#contact-container .contact:last-child
				{
					display: block;
					margin-bottom: 0;
				}
			}
			#contact-container .contact:after
			{
				content: '';
				display: block;
				clear: both;
			}
				#contact-container .contact img
				{
					float: left;
					margin-right: 20px;
				}
				@media only screen and (max-width: 600px)
				{
					#contact-container .contact img
					{
						width: 100%;
						float: none;
						margin: 0 0 20px 0;
					}
				}
				#contact-container .contact .name
				{
					font-size: 1.25rem;
					color: #c5bf33;
					font-weight: bold;
					text-transform: uppercase;
				}
				#contact-container .contact .title
				{
					margin-bottom: 20px;
				}
				
	#footer-outer-container
	{
		background: #c5bf33;
		color: #fff;
		padding: 0 20px;
	}
		#footer-outer-container #footer-inner-container
		{
			max-width: 1200px;
			margin: 0 auto;
			padding: 15px 0;
			text-align: center;
		}
		
.cc_container
{
	border-radius: 0 !important;
	padding-bottom: 67px !important;
	border: none !important;
}
@media only screen and (max-width: 768px)
{
	.cc_container
	{
		padding-bottom: 57px !important;
	}
}
	.cc_container .cc_btn
	{
		border-radius: 0 !important;
		background: #c5bf33 !important;
		color: #fff !important;
		bottom: 17px !important; 
	}
	.cc_container .cc_logo
	{
		display: none !important;
	}
/* text alignment */
.align-left
{
	text-align: left;
}
.align-center
{
	text-align: center;
}
.align-right
{
	text-align: right;
}
.align-justify
{
	text-align: justify;
}

/* float alignments */
.float-left
{
	float: left;
}
.float-right
{
	float: right;
}
.float-none
{
	float: none;
}
.clear
{
	clear: both;
}
.clear-left
{
	clear: left;
}
.clear-right
{
	clear: right;
}
.clear-none
{
	clear: none;
}

.visible,
.display-block
{
	display: block;
}
.visible-inline,
.display-inline
{
	display: inline;
}
.visible-inline-block,
.display-inline-block
{
	display: inline-block;
}
.hidden,
.none
{
	display: none;
}

@media print
{
	.print-visible
	{
		display: block;
	}
	.print-visible-inline
	{
		display: inline;
	}
	.print-visible-inline-block
	{
		display: inline-block;
	}
	.print-hidden
	{
		display: none;
	}
}

.bold,
.strong
{
	font-weight: bold;
}
.italic
{
	font-style: italic;
}
.underline
{
	text-decoration: underline;
}
.overline
{
	text-decoration: overline;
}
.uppercase
{
	text-transform: uppercase;
}
.lowercase
{
	text-transform: lowercase;
}
.capitalize
{
	text-transform: capitalize;
}

.fluid-image
{
	max-width: 100%;
	height: auto;
}
.fluid-image-container img
{
	max-width: 100%;
	height: auto;
}
.fluid-image-upscale
{
	width: 100%;
	height: auto;
}
.fluid-image-upscale-container img
{
	width: 100%;
	height: auto;
}

/**
 * xxl 		>= 1281px
 * xxl-down <= 1281px
 */
@media only screen and (max-width: 1281px)
{
	.xxl-down-visible
	{
		display: block;
	}
	.xxl-down-visible-inline
	{
		display: inline;
	}
	.xxl-down-visible-inline-block
	{
		display: inline-block;
	}
	.xxl-down-hidden
	{
		display: none;
	}
	
	.xxl-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xxl-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xxl-down-align-left
	{
		text-align: left;
	}
	.xxl-down-align-center
	{
		text-align: center;
	}
	.xxl-down-align-right
	{
		text-align: right;
	}
	.xxl-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 1281px)
{
	.xxl-visible
	{
		display: block;
	}
	.xxl-visible-inline
	{
		display: inline;
	}
	.xxl-visible-inline-block
	{
		display: inline-block;
	}
	.xxl-hidden
	{
		display: none;
	}
	
	.xxl-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xxl-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xxl-align-left
	{
		text-align: left;
	}
	.xxl-align-center
	{
		text-align: center;
	}
	.xxl-align-right
	{
		text-align: right;
	}
	.xxl-align-justify
	{
		text-align: justify;
	}
}

/**
 * xl- 		1025px ~ 1281px
 * xl-up 	>= 1025px
 * xl-down 	<= 1281px
 */
@media only screen and (min-width: 1025px)
{
	.xl-up-visible
	{
		display: block;
	}
	.xl-up-visible-inline
	{
		display: inline;
	}
	.xl-up-visible-inline-block
	{
		display: inline-block;
	}
	.xl-up-hidden
	{
		display: none;
	}
	
	.xl-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-up-align-left
	{
		text-align: left;
	}
	.xl-up-align-center
	{
		text-align: center;
	}
	.xl-up-align-right
	{
		text-align: right;
	}
	.xl-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 1281px)
{
	.xl-down-visible
	{
		display: block;
	}
	.xl-down-visible-inline
	{
		display: inline;
	}
	.xl-down-visible-inline-block
	{
		display: inline-block;
	}
	.xl-down-hidden
	{
		display: none;
	}
	
	.xl-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-down-align-left
	{
		text-align: left;
	}
	.xl-down-align-center
	{
		text-align: center;
	}
	.xl-down-align-right
	{
		text-align: right;
	}
	.xl-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1281px)
{
	.xl-visible
	{
		display: block;
	}
	.xl-visible-inline
	{
		display: inline;
	}
	.xl-visible-inline-block
	{
		display: inline-block;
	}
	.xl-hidden
	{
		display: none;
	}
	
	.xl-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xl-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xl-align-left
	{
		text-align: left;
	}
	.xl-align-center
	{
		text-align: center;
	}
	.xl-align-right
	{
		text-align: right;
	}
	.xl-align-justify
	{
		text-align: justify;
	}
}

/**
 * l- 		801px ~ 1025px
 * l-up 	>= 801px
 * l-down 	<= 1025px
 */
@media only screen and (min-width: 801px)
{
	.l-up-visible
	{
		display: block;
	}
	.l-up-visible-inline
	{
		display: inline;
	}
	.l-up-visible-inline-block
	{
		display: inline-block;
	}
	.l-up-hidden
	{
		display: none;
	}
	
	.l-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-up-align-left
	{
		text-align: left;
	}
	.l-up-align-center
	{
		text-align: center;
	}
	.l-up-align-right
	{
		text-align: right;
	}
	.l-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 1025px)
{
	.l-down-visible
	{
		display: block;
	}
	.l-down-visible-inline
	{
		display: inline;
	}
	.l-down-visible-inline-block
	{
		display: inline-block;
	}
	.l-down-hidden
	{
		display: none;
	}
	
	.l-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-down-align-left
	{
		text-align: left;
	}
	.l-down-align-center
	{
		text-align: center;
	}
	.l-down-align-right
	{
		text-align: right;
	}
	.l-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 801px) and (max-width: 1025px)
{
	.l-visible
	{
		display: block;
	}
	.l-visible-inline
	{
		display: inline;
	}
	.l-visible-inline-block
	{
		display: inline-block;
	}
	.l-hidden
	{
		display: none;
	}
	
	.l-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.l-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.l-align-left
	{
		text-align: left;
	}
	.l-align-center
	{
		text-align: center;
	}
	.l-align-right
	{
		text-align: right;
	}
	.l-align-justify
	{
		text-align: justify;
	}
}

/**
 * m- 		600px ~ 801px
 * m-up 	>= 600px
 * m-down 	<= 801px
 */
@media only screen and (min-width: 600px)
{
	.m-up-visible
	{
		display: block;
	}
	.m-up-visible-inline
	{
		display: inline;
	}
	.m-up-visible-inline-block
	{
		display: inline-block;
	}
	.m-up-hidden
	{
		display: none;
	}
	
	.m-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-up-align-left
	{
		text-align: left;
	}
	.m-up-align-center
	{
		text-align: center;
	}
	.m-up-align-right
	{
		text-align: right;
	}
	.m-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 801px)
{
	.m-down-visible
	{
		display: block;
	}
	.m-down-visible-inline
	{
		display: inline;
	}
	.m-down-visible-inline-block
	{
		display: inline-block;
	}
	.m-down-hidden
	{
		display: none;
	}
	
	.m-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-down-align-left
	{
		text-align: left;
	}
	.m-down-align-center
	{
		text-align: center;
	}
	.m-down-align-right
	{
		text-align: right;
	}
	.m-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 600px) and (max-width: 801px)
{
	.m-visible
	{
		display: block;
	}
	.m-visible-inline
	{
		display: inline;
	}
	.m-visible-inline-block
	{
		display: inline-block;
	}
	.m-hidden
	{
		display: none;
	}
	
	.m-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.m-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.m-align-left
	{
		text-align: left;
	}
	.m-align-center
	{
		text-align: center;
	}
	.m-align-right
	{
		text-align: right;
	}
	.m-align-justify
	{
		text-align: justify;
	}
}

/**
 * s- 		480px ~ 600px
 * s-up 	>= 480px
 * s-down 	<= 600px
 */
@media only screen and (min-width: 480px)
{
	.s-up-visible
	{
		display: block;
	}
	.s-up-visible-inline
	{
		display: inline;
	}
	.s-up-visible-inline-block
	{
		display: inline-block;
	}
	.s-up-hidden
	{
		display: none;
	}
	
	.s-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-up-align-left
	{
		text-align: left;
	}
	.s-up-align-center
	{
		text-align: center;
	}
	.s-up-align-right
	{
		text-align: right;
	}
	.s-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 600px)
{
	.s-down-visible
	{
		display: block;
	}
	.s-down-visible-inline
	{
		display: inline;
	}
	.s-down-visible-inline-block
	{
		display: inline-block;
	}
	.s-down-hidden
	{
		display: none;
	}
	
	.s-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-down-align-left
	{
		text-align: left;
	}
	.s-down-align-center
	{
		text-align: center;
	}
	.s-down-align-right
	{
		text-align: right;
	}
	.s-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 480px) and (max-width: 600px)
{
	.s-visible
	{
		display: block;
	}
	.s-visible-inline
	{
		display: inline;
	}
	.s-visible-inline-block
	{
		display: inline-block;
	}
	.s-hidden
	{
		display: none;
	}
	
	.s-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.s-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.s-align-left
	{
		text-align: left;
	}
	.s-align-center
	{
		text-align: center;
	}
	.s-align-right
	{
		text-align: right;
	}
	.s-align-justify
	{
		text-align: justify;
	}
}

/**
 * xs- 		320px ~ 480px
 * xs-up 	>= 320px
 * xs-down 	<= 480px
 */
@media only screen and (min-width: 320px)
{
	.xs-up-visible
	{
		display: block;
	}
	.xs-up-visible-inline
	{
		display: inline;
	}
	.xs-up-visible-inline-block
	{
		display: inline-block;
	}
	.xs-up-hidden
	{
		display: none;
	}
	
	.xs-up-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-up-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-up-align-left
	{
		text-align: left;
	}
	.xs-up-align-center
	{
		text-align: center;
	}
	.xs-up-align-right
	{
		text-align: right;
	}
	.xs-up-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (max-width: 480px)
{
	.xs-down-visible
	{
		display: block;
	}
	.xs-down-visible-inline
	{
		display: inline;
	}
	.xs-down-visible-inline-block
	{
		display: inline-block;
	}
	.xs-down-hidden
	{
		display: none;
	}
	
	.xs-down-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-down-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-down-align-left
	{
		text-align: left;
	}
	.xs-down-align-center
	{
		text-align: center;
	}
	.xs-down-align-right
	{
		text-align: right;
	}
	.xs-down-align-justify
	{
		text-align: justify;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px)
{
	.xs-visible
	{
		display: block;
	}
	.xs-visible-inline
	{
		display: inline;
	}
	.xs-visible-inline-block
	{
		display: inline-block;
	}
	.xs-hidden
	{
		display: none;
	}
	
	.xs-fluid-image
	{
		max-width: 100%;
		height: auto;
	}
	.xs-fluid-image-upscale
	{
		width: 100%;
		height: auto;
	}
	
	.xs-align-left
	{
		text-align: left;
	}
	.xs-align-center
	{
		text-align: center;
	}
	.xs-align-right
	{
		text-align: right;
	}
	.xs-align-justify
	{
		text-align: justify;
	}
}
/*# sourceMappingURL=application.css.map */