.grid-row
{
	margin-left: -45px;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
}
.grid-row.blocked
{
	display: block;
}
.grid-row.blocked:after
{
	clear: both;
	content: '';
	display: block;
}

[class*="col-"]
{
	position: relative;
	padding-bottom: 45px;
	padding-left: 45px;
	box-sizing: border-box;
}
.grid-row.blocked [class*="col-"]
{
	float: left;
}
.grid-row.condensed [class*="col-"]
{
	padding-bottom: 0;
}

.col-flush
{
	padding-bottom: 0;
}

.col-1
{
	width: 8.333333333%;
}
.col-2
{
	width: 16.6666666666%;
}
.col-3
{
	width: 25%;
}
.col-4
{
	width: 33.3333333333%;
}
.col-5
{
	width: 41.6666666666%;
}
.col-6
{
	width: 50%;
}
.col-7
{
	width: 58.3333333333%;
}
.col-8
{
	width: 66.6666666666%;
}
.col-9
{
	width: 75%;
}
.col-10
{
	width: 83.3333333333%;
}
.col-11
{
	width: 91.6666666666%;
}
.col-12
{
	width: 100%;
}

.col-offset-0
{
	margin-left: 0;
}
.col-offset-1
{
	margin-left: 8.333333333%;
}
.col-offset-2
{
	margin-left: 16.6666666666%;
}
.col-offset-3
{
	margin-left: 25%;
}
.col-offset-4
{
	margin-left: 33.3333333333%;
}
.col-offset-5
{
	margin-left: 41.6666666666%;
}
.col-offset-6
{
	margin-left: 50%;
}
.col-offset-7
{
	margin-left: 58.3333333333%;
}
.col-offset-8
{
	margin-left: 66.6666666666%;
}
.col-offset-9
{
	margin-left: 75%;
}
.col-offset-10
{
	margin-left: 83.3333333333%;
}
.col-offset-11
{
	margin-left: 91.6666666666%;
}

.col-push-0
{
	left: 0;
}
.col-push-1
{
	left: 8.333333333%;
}
.col-push-2
{
	left: 16.6666666666%;
}
.col-push-3
{
	left: 25%;
}
.col-push-4
{
	left: 33.3333333333%;
}
.col-push-5
{
	left: 41.6666666666%;
}
.col-push-6
{
	left: 50%;
}
.col-push-7
{
	left: 58.3333333333%;
}
.col-push-8
{
	left: 66.6666666666%;
}
.col-push-9
{
	left: 75%;
}
.col-push-10
{
	left: 83.3333333333%;
}
.col-push-11
{
	left: 91.6666666666%;
}

.col-pull-0
{
	right: 0;
}
.col-pull-1
{
	right: 8.333333333%;
}
.col-pull-2
{
	right: 16.6666666666%;
}
.col-pull-3
{
	right: 25%;
}
.col-pull-4
{
	right: 33.3333333333%;
}
.col-pull-5
{
	right: 41.6666666666%;
}
.col-pull-6
{
	right: 50%;
}
.col-pull-7
{
	right: 58.3333333333%;
}
.col-pull-8
{
	right: 66.6666666666%;
}
.col-pull-9
{
	right: 75%;
}
.col-pull-10
{
	right: 83.3333333333%;
}
.col-pull-11
{
	right: 91.6666666666%;
}

.grid-row:last-child > [class*="col-"]:last-child
{
	padding-bottom: 0;
}

/**
 * xxl 		>= 1281px
 * xxl-down <= 1281px
 */
@media only screen and (max-width: 1281px)
{
	.grid-row.xxl-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}

	.xxl-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xxl-down-col-1
	{
		width: 8.333333333%;
	}
	.xxl-down-col-2
	{
		width: 16.6666666666%;
	}
	.xxl-down-col-3
	{
		width: 25%;
	}
	.xxl-down-col-4
	{
		width: 33.3333333333%;
	}
	.xxl-down-col-5
	{
		width: 41.6666666666%;
	}
	.xxl-down-col-6
	{
		width: 50%;
	}
	.xxl-down-col-7
	{
		width: 58.3333333333%;
	}
	.xxl-down-col-8
	{
		width: 66.6666666666%;
	}
	.xxl-down-col-9
	{
		width: 75%;
	}
	.xxl-down-col-10
	{
		width: 83.3333333333%;
	}
	.xxl-down-col-11
	{
		width: 91.6666666666%;
	}
	.xxl-down-col-12
	{
		width: 100%;
	}
	
	.xxl-down-col-offset-0
	{
		margin-left: 0;
	}
	.xxl-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xxl-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xxl-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xxl-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xxl-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xxl-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xxl-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xxl-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xxl-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xxl-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xxl-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xxl-down-col-push-0
	{
		left: 0;
	}
	.xxl-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xxl-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xxl-down-col-push-3
	{
		left: 25%;
	}
	.xxl-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xxl-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xxl-down-col-push-6
	{
		left: 50%;
	}
	.xxl-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xxl-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xxl-down-col-push-9
	{
		left: 75%;
	}
	.xxl-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xxl-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xxl-down-col-pull-0
	{
		right: 0;
	}
	.xxl-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xxl-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xxl-down-col-pull-3
	{
		right: 25%;
	}
	.xxl-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xxl-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xxl-down-col-pull-6
	{
		right: 50%;
	}
	.xxl-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xxl-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xxl-down-col-pull-9
	{
		right: 75%;
	}
	.xxl-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xxl-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xxl-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 1281px)
{
	.grid-row.xxl-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xxl-col-flush
	{
		padding-bottom: 0;
	}
	
	.xxl-col-1
	{
		width: 8.333333333%;
	}
	.xxl-col-2
	{
		width: 16.6666666666%;
	}
	.xxl-col-3
	{
		width: 25%;
	}
	.xxl-col-4
	{
		width: 33.3333333333%;
	}
	.xxl-col-5
	{
		width: 41.6666666666%;
	}
	.xxl-col-6
	{
		width: 50%;
	}
	.xxl-col-7
	{
		width: 58.3333333333%;
	}
	.xxl-col-8
	{
		width: 66.6666666666%;
	}
	.xxl-col-9
	{
		width: 75%;
	}
	.xxl-col-10
	{
		width: 83.3333333333%;
	}
	.xxl-col-11
	{
		width: 91.6666666666%;
	}
	.xxl-col-12
	{
		width: 100%;
	}
	
	.xxl-col-offset-0
	{
		margin-left: 0;
	}
	.xxl-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xxl-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xxl-col-offset-3
	{
		margin-left: 25%;
	}
	.xxl-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xxl-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xxl-col-offset-6
	{
		margin-left: 50%;
	}
	.xxl-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xxl-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xxl-col-offset-9
	{
		margin-left: 75%;
	}
	.xxl-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xxl-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xxl-col-push-0
	{
		left: 0;
	}
	.xxl-col-push-1
	{
		left: 8.333333333%;
	}
	.xxl-col-push-2
	{
		left: 16.6666666666%;
	}
	.xxl-col-push-3
	{
		left: 25%;
	}
	.xxl-col-push-4
	{
		left: 33.3333333333%;
	}
	.xxl-col-push-5
	{
		left: 41.6666666666%;
	}
	.xxl-col-push-6
	{
		left: 50%;
	}
	.xxl-col-push-7
	{
		left: 58.3333333333%;
	}
	.xxl-col-push-8
	{
		left: 66.6666666666%;
	}
	.xxl-col-push-9
	{
		left: 75%;
	}
	.xxl-col-push-10
	{
		left: 83.3333333333%;
	}
	.xxl-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xxl-col-pull-0
	{
		right: 0;
	}
	.xxl-col-pull-1
	{
		right: 8.333333333%;
	}
	.xxl-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xxl-col-pull-3
	{
		right: 25%;
	}
	.xxl-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xxl-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xxl-col-pull-6
	{
		right: 50%;
	}
	.xxl-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xxl-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xxl-col-pull-9
	{
		right: 75%;
	}
	.xxl-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xxl-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xxl-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * xl- 		1025px ~ 1281px
 * xl-up 	>= 1025px
 * xl-down 	<= 1281px
 */
@media only screen and (min-width: 1025px)
{
	.grid-row.xl-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-up-col-1
	{
		width: 8.333333333%;
	}
	.xl-up-col-2
	{
		width: 16.6666666666%;
	}
	.xl-up-col-3
	{
		width: 25%;
	}
	.xl-up-col-4
	{
		width: 33.3333333333%;
	}
	.xl-up-col-5
	{
		width: 41.6666666666%;
	}
	.xl-up-col-6
	{
		width: 50%;
	}
	.xl-up-col-7
	{
		width: 58.3333333333%;
	}
	.xl-up-col-8
	{
		width: 66.6666666666%;
	}
	.xl-up-col-9
	{
		width: 75%;
	}
	.xl-up-col-10
	{
		width: 83.3333333333%;
	}
	.xl-up-col-11
	{
		width: 91.6666666666%;
	}
	.xl-up-col-12
	{
		width: 100%;
	}
	
	.xl-up-col-offset-0
	{
		margin-left: 0;
	}
	.xl-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-up-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-up-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-up-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-up-col-push-0
	{
		left: 0;
	}
	.xl-up-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-up-col-push-3
	{
		left: 25%;
	}
	.xl-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-up-col-push-6
	{
		left: 50%;
	}
	.xl-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-up-col-push-9
	{
		left: 75%;
	}
	.xl-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-up-col-pull-0
	{
		right: 0;
	}
	.xl-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-up-col-pull-3
	{
		right: 25%;
	}
	.xl-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-up-col-pull-6
	{
		right: 50%;
	}
	.xl-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-up-col-pull-9
	{
		right: 75%;
	}
	.xl-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 1281px)
{
	.grid-row.xl-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-down-col-1
	{
		width: 8.333333333%;
	}
	.xl-down-col-2
	{
		width: 16.6666666666%;
	}
	.xl-down-col-3
	{
		width: 25%;
	}
	.xl-down-col-4
	{
		width: 33.3333333333%;
	}
	.xl-down-col-5
	{
		width: 41.6666666666%;
	}
	.xl-down-col-6
	{
		width: 50%;
	}
	.xl-down-col-7
	{
		width: 58.3333333333%;
	}
	.xl-down-col-8
	{
		width: 66.6666666666%;
	}
	.xl-down-col-9
	{
		width: 75%;
	}
	.xl-down-col-10
	{
		width: 83.3333333333%;
	}
	.xl-down-col-11
	{
		width: 91.6666666666%;
	}
	.xl-down-col-12
	{
		width: 100%;
	}
	
	.xl-down-col-offset-0
	{
		margin-left: 0;
	}
	.xl-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-down-col-push-0
	{
		left: 0;
	}
	.xl-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-down-col-push-3
	{
		left: 25%;
	}
	.xl-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-down-col-push-6
	{
		left: 50%;
	}
	.xl-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-down-col-push-9
	{
		left: 75%;
	}
	.xl-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-down-col-pull-0
	{
		right: 0;
	}
	.xl-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-down-col-pull-3
	{
		right: 25%;
	}
	.xl-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-down-col-pull-6
	{
		right: 50%;
	}
	.xl-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-down-col-pull-9
	{
		right: 75%;
	}
	.xl-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1281px)
{
	.grid-row.xl-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xl-col-flush
	{
		padding-bottom: 0;
	}
	
	.xl-col-1
	{
		width: 8.333333333%;
	}
	.xl-col-2
	{
		width: 16.6666666666%;
	}
	.xl-col-3
	{
		width: 25%;
	}
	.xl-col-4
	{
		width: 33.3333333333%;
	}
	.xl-col-5
	{
		width: 41.6666666666%;
	}
	.xl-col-6
	{
		width: 50%;
	}
	.xl-col-7
	{
		width: 58.3333333333%;
	}
	.xl-col-8
	{
		width: 66.6666666666%;
	}
	.xl-col-9
	{
		width: 75%;
	}
	.xl-col-10
	{
		width: 83.3333333333%;
	}
	.xl-col-11
	{
		width: 91.6666666666%;
	}
	.xl-col-12
	{
		width: 100%;
	}
	
	.xl-col-offset-0
	{
		margin-left: 0;
	}
	.xl-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xl-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xl-col-offset-3
	{
		margin-left: 25%;
	}
	.xl-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xl-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xl-col-offset-6
	{
		margin-left: 50%;
	}
	.xl-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xl-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xl-col-offset-9
	{
		margin-left: 75%;
	}
	.xl-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xl-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xl-col-push-0
	{
		left: 0;
	}
	.xl-col-push-1
	{
		left: 8.333333333%;
	}
	.xl-col-push-2
	{
		left: 16.6666666666%;
	}
	.xl-col-push-3
	{
		left: 25%;
	}
	.xl-col-push-4
	{
		left: 33.3333333333%;
	}
	.xl-col-push-5
	{
		left: 41.6666666666%;
	}
	.xl-col-push-6
	{
		left: 50%;
	}
	.xl-col-push-7
	{
		left: 58.3333333333%;
	}
	.xl-col-push-8
	{
		left: 66.6666666666%;
	}
	.xl-col-push-9
	{
		left: 75%;
	}
	.xl-col-push-10
	{
		left: 83.3333333333%;
	}
	.xl-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xl-col-pull-0
	{
		right: 0;
	}
	.xl-col-pull-1
	{
		right: 8.333333333%;
	}
	.xl-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xl-col-pull-3
	{
		right: 25%;
	}
	.xl-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xl-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xl-col-pull-6
	{
		right: 50%;
	}
	.xl-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xl-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xl-col-pull-9
	{
		right: 75%;
	}
	.xl-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xl-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xl-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * l- 		801px ~ 1025px
 * l-up 	>= 801px
 * l-down 	<= 1025px
 */
@media only screen and (min-width: 801px)
{
	.grid-row.l-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-up-col-1
	{
		width: 8.333333333%;
	}
	.l-up-col-2
	{
		width: 16.6666666666%;
	}
	.l-up-col-3
	{
		width: 25%;
	}
	.l-up-col-4
	{
		width: 33.3333333333%;
	}
	.l-up-col-5
	{
		width: 41.6666666666%;
	}
	.l-up-col-6
	{
		width: 50%;
	}
	.l-up-col-7
	{
		width: 58.3333333333%;
	}
	.l-up-col-8
	{
		width: 66.6666666666%;
	}
	.l-up-col-9
	{
		width: 75%;
	}
	.l-up-col-10
	{
		width: 83.3333333333%;
	}
	.l-up-col-11
	{
		width: 91.6666666666%;
	}
	.l-up-col-12
	{
		width: 100%;
	}
	
	.l-up-col-offset-0
	{
		margin-left: 0;
	}
	.l-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-up-col-offset-3
	{
		margin-left: 25%;
	}
	.l-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-up-col-offset-6
	{
		margin-left: 50%;
	}
	.l-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-up-col-offset-9
	{
		margin-left: 75%;
	}
	.l-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-up-col-push-0
	{
		left: 0;
	}
	.l-up-col-push-1
	{
		left: 8.333333333%;
	}
	.l-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-up-col-push-3
	{
		left: 25%;
	}
	.l-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-up-col-push-6
	{
		left: 50%;
	}
	.l-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-up-col-push-9
	{
		left: 75%;
	}
	.l-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-up-col-pull-0
	{
		right: 0;
	}
	.l-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-up-col-pull-3
	{
		right: 25%;
	}
	.l-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-up-col-pull-6
	{
		right: 50%;
	}
	.l-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-up-col-pull-9
	{
		right: 75%;
	}
	.l-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 1025px)
{
	.grid-row.l-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-down-col-1
	{
		width: 8.333333333%;
	}
	.l-down-col-2
	{
		width: 16.6666666666%;
	}
	.l-down-col-3
	{
		width: 25%;
	}
	.l-down-col-4
	{
		width: 33.3333333333%;
	}
	.l-down-col-5
	{
		width: 41.6666666666%;
	}
	.l-down-col-6
	{
		width: 50%;
	}
	.l-down-col-7
	{
		width: 58.3333333333%;
	}
	.l-down-col-8
	{
		width: 66.6666666666%;
	}
	.l-down-col-9
	{
		width: 75%;
	}
	.l-down-col-10
	{
		width: 83.3333333333%;
	}
	.l-down-col-11
	{
		width: 91.6666666666%;
	}
	.l-down-col-12
	{
		width: 100%;
	}
	
	.l-down-col-offset-0
	{
		margin-left: 0;
	}
	.l-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-down-col-offset-3
	{
		margin-left: 25%;
	}
	.l-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-down-col-offset-6
	{
		margin-left: 50%;
	}
	.l-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-down-col-offset-9
	{
		margin-left: 75%;
	}
	.l-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-down-col-push-0
	{
		left: 0;
	}
	.l-down-col-push-1
	{
		left: 8.333333333%;
	}
	.l-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-down-col-push-3
	{
		left: 25%;
	}
	.l-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-down-col-push-6
	{
		left: 50%;
	}
	.l-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-down-col-push-9
	{
		left: 75%;
	}
	.l-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-down-col-pull-0
	{
		right: 0;
	}
	.l-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-down-col-pull-3
	{
		right: 25%;
	}
	.l-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-down-col-pull-6
	{
		right: 50%;
	}
	.l-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-down-col-pull-9
	{
		right: 75%;
	}
	.l-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 801px) and (max-width: 1025px)
{
	.grid-row.l-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.l-col-flush
	{
		padding-bottom: 0;
	}
	
	.l-col-1
	{
		width: 8.333333333%;
	}
	.l-col-2
	{
		width: 16.6666666666%;
	}
	.l-col-3
	{
		width: 25%;
	}
	.l-col-4
	{
		width: 33.3333333333%;
	}
	.l-col-5
	{
		width: 41.6666666666%;
	}
	.l-col-6
	{
		width: 50%;
	}
	.l-col-7
	{
		width: 58.3333333333%;
	}
	.l-col-8
	{
		width: 66.6666666666%;
	}
	.l-col-9
	{
		width: 75%;
	}
	.l-col-10
	{
		width: 83.3333333333%;
	}
	.l-col-11
	{
		width: 91.6666666666%;
	}
	.l-col-12
	{
		width: 100%;
	}
	
	.l-col-offset-0
	{
		margin-left: 0;
	}
	.l-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.l-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.l-col-offset-3
	{
		margin-left: 25%;
	}
	.l-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.l-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.l-col-offset-6
	{
		margin-left: 50%;
	}
	.l-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.l-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.l-col-offset-9
	{
		margin-left: 75%;
	}
	.l-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.l-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.l-col-push-0
	{
		left: 0;
	}
	.l-col-push-1
	{
		left: 8.333333333%;
	}
	.l-col-push-2
	{
		left: 16.6666666666%;
	}
	.l-col-push-3
	{
		left: 25%;
	}
	.l-col-push-4
	{
		left: 33.3333333333%;
	}
	.l-col-push-5
	{
		left: 41.6666666666%;
	}
	.l-col-push-6
	{
		left: 50%;
	}
	.l-col-push-7
	{
		left: 58.3333333333%;
	}
	.l-col-push-8
	{
		left: 66.6666666666%;
	}
	.l-col-push-9
	{
		left: 75%;
	}
	.l-col-push-10
	{
		left: 83.3333333333%;
	}
	.l-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.l-col-pull-0
	{
		right: 0;
	}
	.l-col-pull-1
	{
		right: 8.333333333%;
	}
	.l-col-pull-2
	{
		right: 16.6666666666%;
	}
	.l-col-pull-3
	{
		right: 25%;
	}
	.l-col-pull-4
	{
		right: 33.3333333333%;
	}
	.l-col-pull-5
	{
		right: 41.6666666666%;
	}
	.l-col-pull-6
	{
		right: 50%;
	}
	.l-col-pull-7
	{
		right: 58.3333333333%;
	}
	.l-col-pull-8
	{
		right: 66.6666666666%;
	}
	.l-col-pull-9
	{
		right: 75%;
	}
	.l-col-pull-10
	{
		right: 83.3333333333%;
	}
	.l-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="l-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * m- 		600px ~ 801px
 * m-up 	>= 600px
 * m-down 	<= 801px
 */
@media only screen and (min-width: 600px)
{
	.grid-row.m-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-up-col-1
	{
		width: 8.333333333%;
	}
	.m-up-col-2
	{
		width: 16.6666666666%;
	}
	.m-up-col-3
	{
		width: 25%;
	}
	.m-up-col-4
	{
		width: 33.3333333333%;
	}
	.m-up-col-5
	{
		width: 41.6666666666%;
	}
	.m-up-col-6
	{
		width: 50%;
	}
	.m-up-col-7
	{
		width: 58.3333333333%;
	}
	.m-up-col-8
	{
		width: 66.6666666666%;
	}
	.m-up-col-9
	{
		width: 75%;
	}
	.m-up-col-10
	{
		width: 83.3333333333%;
	}
	.m-up-col-11
	{
		width: 91.6666666666%;
	}
	.m-up-col-12
	{
		width: 100%;
	}
	
	.m-up-col-offset-0
	{
		margin-left: 0;
	}
	.m-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-up-col-offset-3
	{
		margin-left: 25%;
	}
	.m-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-up-col-offset-6
	{
		margin-left: 50%;
	}
	.m-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-up-col-offset-9
	{
		margin-left: 75%;
	}
	.m-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-up-col-push-0
	{
		left: 0;
	}
	.m-up-col-push-1
	{
		left: 8.333333333%;
	}
	.m-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-up-col-push-3
	{
		left: 25%;
	}
	.m-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-up-col-push-6
	{
		left: 50%;
	}
	.m-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-up-col-push-9
	{
		left: 75%;
	}
	.m-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-up-col-pull-0
	{
		right: 0;
	}
	.m-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-up-col-pull-3
	{
		right: 25%;
	}
	.m-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-up-col-pull-6
	{
		right: 50%;
	}
	.m-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-up-col-pull-9
	{
		right: 75%;
	}
	.m-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 801px)
{
	.grid-row.m-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-down-col-1
	{
		width: 8.333333333%;
	}
	.m-down-col-2
	{
		width: 16.6666666666%;
	}
	.m-down-col-3
	{
		width: 25%;
	}
	.m-down-col-4
	{
		width: 33.3333333333%;
	}
	.m-down-col-5
	{
		width: 41.6666666666%;
	}
	.m-down-col-6
	{
		width: 50%;
	}
	.m-down-col-7
	{
		width: 58.3333333333%;
	}
	.m-down-col-8
	{
		width: 66.6666666666%;
	}
	.m-down-col-9
	{
		width: 75%;
	}
	.m-down-col-10
	{
		width: 83.3333333333%;
	}
	.m-down-col-11
	{
		width: 91.6666666666%;
	}
	.m-down-col-12
	{
		width: 100%;
	}
	
	.m-down-col-offset-0
	{
		margin-left: 0;
	}
	.m-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-down-col-offset-3
	{
		margin-left: 25%;
	}
	.m-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-down-col-offset-6
	{
		margin-left: 50%;
	}
	.m-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-down-col-offset-9
	{
		margin-left: 75%;
	}
	.m-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-down-col-push-0
	{
		left: 0;
	}
	.m-down-col-push-1
	{
		left: 8.333333333%;
	}
	.m-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-down-col-push-3
	{
		left: 25%;
	}
	.m-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-down-col-push-6
	{
		left: 50%;
	}
	.m-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-down-col-push-9
	{
		left: 75%;
	}
	.m-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-down-col-pull-0
	{
		right: 0;
	}
	.m-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-down-col-pull-3
	{
		right: 25%;
	}
	.m-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-down-col-pull-6
	{
		right: 50%;
	}
	.m-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-down-col-pull-9
	{
		right: 75%;
	}
	.m-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 600px) and (max-width: 801px)
{
	.grid-row.m-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.m-col-flush
	{
		padding-bottom: 0;
	}
	
	.m-col-1
	{
		width: 8.333333333%;
	}
	.m-col-2
	{
		width: 16.6666666666%;
	}
	.m-col-3
	{
		width: 25%;
	}
	.m-col-4
	{
		width: 33.3333333333%;
	}
	.m-col-5
	{
		width: 41.6666666666%;
	}
	.m-col-6
	{
		width: 50%;
	}
	.m-col-7
	{
		width: 58.3333333333%;
	}
	.m-col-8
	{
		width: 66.6666666666%;
	}
	.m-col-9
	{
		width: 75%;
	}
	.m-col-10
	{
		width: 83.3333333333%;
	}
	.m-col-11
	{
		width: 91.6666666666%;
	}
	.m-col-12
	{
		width: 100%;
	}
	
	.m-col-offset-0
	{
		margin-left: 0;
	}
	.m-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.m-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.m-col-offset-3
	{
		margin-left: 25%;
	}
	.m-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.m-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.m-col-offset-6
	{
		margin-left: 50%;
	}
	.m-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.m-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.m-col-offset-9
	{
		margin-left: 75%;
	}
	.m-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.m-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.m-col-push-0
	{
		left: 0;
	}
	.m-col-push-1
	{
		left: 8.333333333%;
	}
	.m-col-push-2
	{
		left: 16.6666666666%;
	}
	.m-col-push-3
	{
		left: 25%;
	}
	.m-col-push-4
	{
		left: 33.3333333333%;
	}
	.m-col-push-5
	{
		left: 41.6666666666%;
	}
	.m-col-push-6
	{
		left: 50%;
	}
	.m-col-push-7
	{
		left: 58.3333333333%;
	}
	.m-col-push-8
	{
		left: 66.6666666666%;
	}
	.m-col-push-9
	{
		left: 75%;
	}
	.m-col-push-10
	{
		left: 83.3333333333%;
	}
	.m-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.m-col-pull-0
	{
		right: 0;
	}
	.m-col-pull-1
	{
		right: 8.333333333%;
	}
	.m-col-pull-2
	{
		right: 16.6666666666%;
	}
	.m-col-pull-3
	{
		right: 25%;
	}
	.m-col-pull-4
	{
		right: 33.3333333333%;
	}
	.m-col-pull-5
	{
		right: 41.6666666666%;
	}
	.m-col-pull-6
	{
		right: 50%;
	}
	.m-col-pull-7
	{
		right: 58.3333333333%;
	}
	.m-col-pull-8
	{
		right: 66.6666666666%;
	}
	.m-col-pull-9
	{
		right: 75%;
	}
	.m-col-pull-10
	{
		right: 83.3333333333%;
	}
	.m-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="m-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * s- 		480px ~ 600px
 * s-up 	>= 480px
 * s-down 	<= 600px
 */
@media only screen and (min-width: 480px)
{
	.grid-row.s-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-up-col-1
	{
		width: 8.333333333%;
	}
	.s-up-col-2
	{
		width: 16.6666666666%;
	}
	.s-up-col-3
	{
		width: 25%;
	}
	.s-up-col-4
	{
		width: 33.3333333333%;
	}
	.s-up-col-5
	{
		width: 41.6666666666%;
	}
	.s-up-col-6
	{
		width: 50%;
	}
	.s-up-col-7
	{
		width: 58.3333333333%;
	}
	.s-up-col-8
	{
		width: 66.6666666666%;
	}
	.s-up-col-9
	{
		width: 75%;
	}
	.s-up-col-10
	{
		width: 83.3333333333%;
	}
	.s-up-col-11
	{
		width: 91.6666666666%;
	}
	.s-up-col-12
	{
		width: 100%;
	}
	
	.s-up-col-offset-0
	{
		margin-left: 0;
	}
	.s-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-up-col-offset-3
	{
		margin-left: 25%;
	}
	.s-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-up-col-offset-6
	{
		margin-left: 50%;
	}
	.s-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-up-col-offset-9
	{
		margin-left: 75%;
	}
	.s-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-up-col-push-0
	{
		left: 0;
	}
	.s-up-col-push-1
	{
		left: 8.333333333%;
	}
	.s-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-up-col-push-3
	{
		left: 25%;
	}
	.s-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-up-col-push-6
	{
		left: 50%;
	}
	.s-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-up-col-push-9
	{
		left: 75%;
	}
	.s-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-up-col-pull-0
	{
		right: 0;
	}
	.s-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-up-col-pull-3
	{
		right: 25%;
	}
	.s-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-up-col-pull-6
	{
		right: 50%;
	}
	.s-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-up-col-pull-9
	{
		right: 75%;
	}
	.s-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 600px)
{
	.grid-row.s-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-down-col-1
	{
		width: 8.333333333%;
	}
	.s-down-col-2
	{
		width: 16.6666666666%;
	}
	.s-down-col-3
	{
		width: 25%;
	}
	.s-down-col-4
	{
		width: 33.3333333333%;
	}
	.s-down-col-5
	{
		width: 41.6666666666%;
	}
	.s-down-col-6
	{
		width: 50%;
	}
	.s-down-col-7
	{
		width: 58.3333333333%;
	}
	.s-down-col-8
	{
		width: 66.6666666666%;
	}
	.s-down-col-9
	{
		width: 75%;
	}
	.s-down-col-10
	{
		width: 83.3333333333%;
	}
	.s-down-col-11
	{
		width: 91.6666666666%;
	}
	.s-down-col-12
	{
		width: 100%;
	}
	
	.s-down-col-offset-0
	{
		margin-left: 0;
	}
	.s-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-down-col-offset-3
	{
		margin-left: 25%;
	}
	.s-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-down-col-offset-6
	{
		margin-left: 50%;
	}
	.s-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-down-col-offset-9
	{
		margin-left: 75%;
	}
	.s-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-down-col-push-0
	{
		left: 0;
	}
	.s-down-col-push-1
	{
		left: 8.333333333%;
	}
	.s-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-down-col-push-3
	{
		left: 25%;
	}
	.s-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-down-col-push-6
	{
		left: 50%;
	}
	.s-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-down-col-push-9
	{
		left: 75%;
	}
	.s-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-down-col-pull-0
	{
		right: 0;
	}
	.s-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-down-col-pull-3
	{
		right: 25%;
	}
	.s-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-down-col-pull-6
	{
		right: 50%;
	}
	.s-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-down-col-pull-9
	{
		right: 75%;
	}
	.s-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 480px) and (max-width: 600px)
{
	.grid-row.s-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.s-col-flush
	{
		padding-bottom: 0;
	}
	
	.s-col-1
	{
		width: 8.333333333%;
	}
	.s-col-2
	{
		width: 16.6666666666%;
	}
	.s-col-3
	{
		width: 25%;
	}
	.s-col-4
	{
		width: 33.3333333333%;
	}
	.s-col-5
	{
		width: 41.6666666666%;
	}
	.s-col-6
	{
		width: 50%;
	}
	.s-col-7
	{
		width: 58.3333333333%;
	}
	.s-col-8
	{
		width: 66.6666666666%;
	}
	.s-col-9
	{
		width: 75%;
	}
	.s-col-10
	{
		width: 83.3333333333%;
	}
	.s-col-11
	{
		width: 91.6666666666%;
	}
	.s-col-12
	{
		width: 100%;
	}
	
	.s-col-offset-0
	{
		margin-left: 0;
	}
	.s-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.s-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.s-col-offset-3
	{
		margin-left: 25%;
	}
	.s-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.s-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.s-col-offset-6
	{
		margin-left: 50%;
	}
	.s-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.s-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.s-col-offset-9
	{
		margin-left: 75%;
	}
	.s-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.s-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.s-col-push-0
	{
		left: 0;
	}
	.s-col-push-1
	{
		left: 8.333333333%;
	}
	.s-col-push-2
	{
		left: 16.6666666666%;
	}
	.s-col-push-3
	{
		left: 25%;
	}
	.s-col-push-4
	{
		left: 33.3333333333%;
	}
	.s-col-push-5
	{
		left: 41.6666666666%;
	}
	.s-col-push-6
	{
		left: 50%;
	}
	.s-col-push-7
	{
		left: 58.3333333333%;
	}
	.s-col-push-8
	{
		left: 66.6666666666%;
	}
	.s-col-push-9
	{
		left: 75%;
	}
	.s-col-push-10
	{
		left: 83.3333333333%;
	}
	.s-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.s-col-pull-0
	{
		right: 0;
	}
	.s-col-pull-1
	{
		right: 8.333333333%;
	}
	.s-col-pull-2
	{
		right: 16.6666666666%;
	}
	.s-col-pull-3
	{
		right: 25%;
	}
	.s-col-pull-4
	{
		right: 33.3333333333%;
	}
	.s-col-pull-5
	{
		right: 41.6666666666%;
	}
	.s-col-pull-6
	{
		right: 50%;
	}
	.s-col-pull-7
	{
		right: 58.3333333333%;
	}
	.s-col-pull-8
	{
		right: 66.6666666666%;
	}
	.s-col-pull-9
	{
		right: 75%;
	}
	.s-col-pull-10
	{
		right: 83.3333333333%;
	}
	.s-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="s-col-"]:last-child
	{
		padding-bottom: 0;
	}
}

/**
 * xs- 		320px ~ 480px
 * xs-up 	>= 320px
 * xs-down 	<= 480px
 */
@media only screen and (min-width: 320px)
{
	.grid-row.xs-up-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-up-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-up-col-1
	{
		width: 8.333333333%;
	}
	.xs-up-col-2
	{
		width: 16.6666666666%;
	}
	.xs-up-col-3
	{
		width: 25%;
	}
	.xs-up-col-4
	{
		width: 33.3333333333%;
	}
	.xs-up-col-5
	{
		width: 41.6666666666%;
	}
	.xs-up-col-6
	{
		width: 50%;
	}
	.xs-up-col-7
	{
		width: 58.3333333333%;
	}
	.xs-up-col-8
	{
		width: 66.6666666666%;
	}
	.xs-up-col-9
	{
		width: 75%;
	}
	.xs-up-col-10
	{
		width: 83.3333333333%;
	}
	.xs-up-col-11
	{
		width: 91.6666666666%;
	}
	.xs-up-col-12
	{
		width: 100%;
	}
	
	.xs-up-col-offset-0
	{
		margin-left: 0;
	}
	.xs-up-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-up-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-up-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-up-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-up-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-up-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-up-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-up-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-up-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-up-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-up-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-up-col-push-0
	{
		left: 0;
	}
	.xs-up-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-up-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-up-col-push-3
	{
		left: 25%;
	}
	.xs-up-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-up-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-up-col-push-6
	{
		left: 50%;
	}
	.xs-up-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-up-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-up-col-push-9
	{
		left: 75%;
	}
	.xs-up-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-up-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-up-col-pull-0
	{
		right: 0;
	}
	.xs-up-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-up-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-up-col-pull-3
	{
		right: 25%;
	}
	.xs-up-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-up-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-up-col-pull-6
	{
		right: 50%;
	}
	.xs-up-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-up-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-up-col-pull-9
	{
		right: 75%;
	}
	.xs-up-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-up-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-up-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 480px)
{
	.grid-row.xs-down-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-down-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-down-col-1
	{
		width: 8.333333333%;
	}
	.xs-down-col-2
	{
		width: 16.6666666666%;
	}
	.xs-down-col-3
	{
		width: 25%;
	}
	.xs-down-col-4
	{
		width: 33.3333333333%;
	}
	.xs-down-col-5
	{
		width: 41.6666666666%;
	}
	.xs-down-col-6
	{
		width: 50%;
	}
	.xs-down-col-7
	{
		width: 58.3333333333%;
	}
	.xs-down-col-8
	{
		width: 66.6666666666%;
	}
	.xs-down-col-9
	{
		width: 75%;
	}
	.xs-down-col-10
	{
		width: 83.3333333333%;
	}
	.xs-down-col-11
	{
		width: 91.6666666666%;
	}
	.xs-down-col-12
	{
		width: 100%;
	}
	
	.xs-down-col-offset-0
	{
		margin-left: 0;
	}
	.xs-down-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-down-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-down-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-down-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-down-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-down-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-down-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-down-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-down-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-down-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-down-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-down-col-push-0
	{
		left: 0;
	}
	.xs-down-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-down-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-down-col-push-3
	{
		left: 25%;
	}
	.xs-down-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-down-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-down-col-push-6
	{
		left: 50%;
	}
	.xs-down-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-down-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-down-col-push-9
	{
		left: 75%;
	}
	.xs-down-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-down-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-down-col-pull-0
	{
		right: 0;
	}
	.xs-down-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-down-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-down-col-pull-3
	{
		right: 25%;
	}
	.xs-down-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-down-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-down-col-pull-6
	{
		right: 50%;
	}
	.xs-down-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-down-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-down-col-pull-9
	{
		right: 75%;
	}
	.xs-down-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-down-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-down-col-"]:last-child
	{
		padding-bottom: 0;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px)
{
	.grid-row.xs-condensed [class*="col-"]
	{
		padding-bottom: 0;
	}
	
	.xs-col-flush
	{
		padding-bottom: 0;
	}
	
	.xs-col-1
	{
		width: 8.333333333%;
	}
	.xs-col-2
	{
		width: 16.6666666666%;
	}
	.xs-col-3
	{
		width: 25%;
	}
	.xs-col-4
	{
		width: 33.3333333333%;
	}
	.xs-col-5
	{
		width: 41.6666666666%;
	}
	.xs-col-6
	{
		width: 50%;
	}
	.xs-col-7
	{
		width: 58.3333333333%;
	}
	.xs-col-8
	{
		width: 66.6666666666%;
	}
	.xs-col-9
	{
		width: 75%;
	}
	.xs-col-10
	{
		width: 83.3333333333%;
	}
	.xs-col-11
	{
		width: 91.6666666666%;
	}
	.xs-col-12
	{
		width: 100%;
	}
	
	.xs-col-offset-0
	{
		margin-left: 0;
	}
	.xs-col-offset-1
	{
		margin-left: 8.333333333%;
	}
	.xs-col-offset-2
	{
		margin-left: 16.6666666666%;
	}
	.xs-col-offset-3
	{
		margin-left: 25%;
	}
	.xs-col-offset-4
	{
		margin-left: 33.3333333333%;
	}
	.xs-col-offset-5
	{
		margin-left: 41.6666666666%;
	}
	.xs-col-offset-6
	{
		margin-left: 50%;
	}
	.xs-col-offset-7
	{
		margin-left: 58.3333333333%;
	}
	.xs-col-offset-8
	{
		margin-left: 66.6666666666%;
	}
	.xs-col-offset-9
	{
		margin-left: 75%;
	}
	.xs-col-offset-10
	{
		margin-left: 83.3333333333%;
	}
	.xs-col-offset-11
	{
		margin-left: 91.6666666666%;
	}
	
	.xs-col-push-0
	{
		left: 0;
	}
	.xs-col-push-1
	{
		left: 8.333333333%;
	}
	.xs-col-push-2
	{
		left: 16.6666666666%;
	}
	.xs-col-push-3
	{
		left: 25%;
	}
	.xs-col-push-4
	{
		left: 33.3333333333%;
	}
	.xs-col-push-5
	{
		left: 41.6666666666%;
	}
	.xs-col-push-6
	{
		left: 50%;
	}
	.xs-col-push-7
	{
		left: 58.3333333333%;
	}
	.xs-col-push-8
	{
		left: 66.6666666666%;
	}
	.xs-col-push-9
	{
		left: 75%;
	}
	.xs-col-push-10
	{
		left: 83.3333333333%;
	}
	.xs-col-push-11
	{
		left: 91.6666666666%;
	}
	
	.xs-col-pull-0
	{
		right: 0;
	}
	.xs-col-pull-1
	{
		right: 8.333333333%;
	}
	.xs-col-pull-2
	{
		right: 16.6666666666%;
	}
	.xs-col-pull-3
	{
		right: 25%;
	}
	.xs-col-pull-4
	{
		right: 33.3333333333%;
	}
	.xs-col-pull-5
	{
		right: 41.6666666666%;
	}
	.xs-col-pull-6
	{
		right: 50%;
	}
	.xs-col-pull-7
	{
		right: 58.3333333333%;
	}
	.xs-col-pull-8
	{
		right: 66.6666666666%;
	}
	.xs-col-pull-9
	{
		right: 75%;
	}
	.xs-col-pull-10
	{
		right: 83.3333333333%;
	}
	.xs-col-pull-11
	{
		right: 91.6666666666%;
	}
	
	.grid-row:last-child > [class*="xs-col-"]:last-child
	{
		padding-bottom: 0;
	}
}